import { useMemo } from 'react';
import { CCol, CProgress, CProgressBar } from '@coreui/react';

const Progress = ({ step, title }) => {
    const value = useMemo(() => {
        if (step === 0) return 33.33;
        if (step === 1) return 66.66;
        return 100;
    }, [step]);

    return (
        <CCol xs={12} sm={12} lg={12}>
            <span className="progress-bar-text">
                {step + 1}/3 {title}
            </span>
            <CProgress className="progress-bar">
                <CProgressBar color="warning" value={value} />
            </CProgress>
        </CCol>
    );
};

export default Progress;
