import axios from 'axios';

import { getCustomerId } from '@utils/helpers';

const BASE_URL = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PRACTITIONER_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_DC_API_PATH
}/`;
const API_VERSION = 'v2';

export async function getCalendars(userToken, instanceId) {
    const config = {
        headers: {
            Authorization: userToken,
            version: API_VERSION,
            customer_id: getCustomerId(),
        },
    };
    try {
        const url = new URL(`${BASE_URL}calendars`);
        url.search = new URLSearchParams({
            instance_id: instanceId,
        }).toString();

        const res = await axios.get(url.toString(), config);
        return res;
    } catch (error) {
        return error;
    }
}
