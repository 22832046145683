import { CCardBody, CCardText, CCardTitle } from '@coreui/react';

const Kpi = ({ number, text }) => {
    return (
        <CCardBody className="text-center">
            <CCardTitle style={{ fontSize: '3.5rem' }}>{number}</CCardTitle>
            <CCardText>{text}</CCardText>
        </CCardBody>
    );
};

export default Kpi;
