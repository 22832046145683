import { useContext, useEffect, useState } from 'react';
import { CButton, CContainer, CForm, CFormInput } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import cloneDeep from 'lodash.clonedeep';
import CIcon from '@coreui/icons-react';
import { cilReload } from '@coreui/icons';

import HeraklesPatientDetails from '../../components/herakles/HeraklesPatientDetails';
import { useHeraklesStore } from '../../zustandStore';
import { getExternalPatients } from '@utils/helpers';
import AccessDeniedFallback from '../pages/access-denied/AccessDeniedFallback';
import FailedFallback from '../pages/failed/FailedFallback';
import PatientEntry from '../../components/herakles/PatientEntry';
import LoadingPage from '../pages/loading/LoadingPage';
import Alert from '../../components/Alert';
import AWSContext from '@context/AWSContext';
import { FilledFormsState } from '@utils/constants';

export const requiredForms = ['privacy-herakles', 'communication-channels-herakles', 'admission-herakles'];

const HeraklesForms = () => {
    const { userToken } = useContext(AWSContext);
    const { t } = useTranslation();
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const loading = useHeraklesStore((state) => state.loading);
    const patients = useHeraklesStore((state) => state.patients);
    const isAccessDenied = useHeraklesStore((state) => state.isAccessDenied);
    const hasFailed = useHeraklesStore((state) => state.hasFailed);

    const checkRequiredForms = (data) => {
        const clonedData = cloneDeep(data);

        clonedData.forEach((entry) => {
            let filledRequiredForms = 0;

            const filledForms = entry.status_data.data.status_data.reduce((obj, form) => {
                // eslint-disable-next-line no-param-reassign
                obj[form] = form;
                return obj;
            }, {});

            requiredForms.forEach((form) => {
                if (filledForms[form]) filledRequiredForms += 1;
            });

            // eslint-disable-next-line no-param-reassign
            if (filledRequiredForms === requiredForms.length) entry.filledForms = FilledFormsState.ALL;
            // eslint-disable-next-line no-param-reassign
            else if (filledRequiredForms > 0) entry.filledForms = FilledFormsState.SOME;
            // eslint-disable-next-line no-param-reassign
            else entry.filledForms = FilledFormsState.NONE;
        });

        return clonedData;
    };

    const updateSelectedPatient = (item) => {
        if (detailsLoading) return;

        if (item.user_id === selectedPatient?.user_id) {
            setSelectedPatient(null);
        } else {
            setSelectedPatient(item);
            setDetailsLoading(true);
        }
    };

    const getPatients = async () => {
        const [data, statusCode] = await getExternalPatients(userToken);
        if (data) {
            const checkedData = checkRequiredForms(data);
            useHeraklesStore.setState({ patients: checkedData, loading: false });
            setFilteredPatients(checkedData);
            setSearch('');
        } else if (statusCode === 400) useHeraklesStore.setState({ isAccessDenied: true, loading: false });
        else useHeraklesStore.setState({ hasFailed: true, loading: false });
    };

    useEffect(() => {
        if (patients.length === 0) getPatients();
    }, []);

    const reloadData = async () => {
        useHeraklesStore.setState({ loading: true });
        setSelectedPatient(null);
        await getPatients();
    };

    useEffect(() => {
        setSelectedPatient(null);
        if (search) {
            setFilteredPatients(
                patients.filter((item) => {
                    return (
                        item.personal_data.data.personal.first_name
                            .concat(item.personal_data.data.personal.last_name)
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                        item.personal_data.data.personal.date_of_birth.includes(search)
                    );
                })
            );
        } else setFilteredPatients(patients);
    }, [search]);

    if (loading) {
        return <LoadingPage />;
    }

    if (isAccessDenied) return <AccessDeniedFallback />;
    if (hasFailed) return <FailedFallback reloadData={reloadData} />;

    return (
        <CContainer>
            <CContainer
                className="m-0 p-0"
                style={{
                    backdropFilter: 'blur(0.3rem)',
                    zIndex: 5,
                    width: '100%',
                }}
            >
                <div
                    className="mb-3"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <CForm>
                        <CFormInput
                            style={{ width: '15rem' }}
                            type="text"
                            id="search"
                            value={search}
                            placeholder={t('Search')}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </CForm>

                    <div className="w-50 d-flex justify-content-end gap-3">
                        <div
                            className="px-3 d-flex gap-2 align-items-center"
                            style={{ height: '38px', cursor: 'pointer' }}
                            onClick={reloadData}
                        >
                            <CIcon icon={cilReload} />
                        </div>
                        <CButton
                            color="secondary"
                            onClick={() =>
                                window.open(
                                    `https://${
                                        import.meta.env.VITE_ENVIRONMENT === 'dev' ? 'dev.' : ''
                                    }patients.eterno-health.io/all-forms-checkin`,
                                    '_blank'
                                )
                            }
                        >
                            {t('Open Forms New Patient Cta')}
                        </CButton>
                    </div>
                </div>
                <Alert />
            </CContainer>
            <div style={{ display: 'flex', height: '70vh' }}>
                <TableContainer
                    component={Paper}
                    style={{
                        width: '60%',
                        borderRight: '1px solid #ccc',
                    }}
                >
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>{t('Vorname')}</b>
                                </TableCell>
                                <TableCell>
                                    <b>{t('Nachname')}</b>
                                </TableCell>
                                <TableCell>
                                    <b>{t('Geburtsdatum')}</b>
                                </TableCell>
                                <TableCell>
                                    <b>{t('Status')}</b>
                                </TableCell>
                                <TableCell>
                                    <b />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {filteredPatients.map((item) => (
                            <PatientEntry
                                key={item.user_id}
                                item={item}
                                selectedPatient={selectedPatient}
                                updateSelectedPatient={updateSelectedPatient}
                                detailsLoading={detailsLoading}
                            />
                        ))}
                    </Table>
                </TableContainer>
                {selectedPatient && (
                    <HeraklesPatientDetails
                        patient={selectedPatient}
                        detailsLoading={detailsLoading}
                        setDetailsLoading={setDetailsLoading}
                    />
                )}
            </div>
        </CContainer>
    );
};
export default HeraklesForms;
