import { useContext } from 'react';

import AWSContext from '@context/AWSContext';
// ==============================|| AUTH HOOKS ||============================== //
const useAuth = () => {
    const context = useContext(AWSContext);
    if (!context) throw new Error('context must be use inside provider');
    return context;
};
export default useAuth;
