import { CButton, CCard, CFormInput, CFormSwitch, CTooltip } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const EditCustomers = ({
    search,
    setSearch,
    selectedCustomers,
    setSelectedCustomers,
    allUserCustomers,
    initialSelectedCustomers,
    userArray,
}) => {
    const { t } = useTranslation();

    const removeCustomer = (customer) => {
        const newSelectedCustomers = { ...selectedCustomers };
        delete newSelectedCustomers[customer];

        setSelectedCustomers(newSelectedCustomers);
    };

    const addCustomer = (customer) => {
        const newSelectedCustomers = { ...selectedCustomers };
        newSelectedCustomers[customer] = {
            ...(selectedCustomers[customer] ?? allUserCustomers[customer]),
            setForAllUsers: true,
        };

        setSelectedCustomers(newSelectedCustomers);
    };

    // TODO add admin user warning

    return (
        <>
            <CFormInput placeholder={t('Search')} value={search} onChange={(e) => setSearch(e.target.value)} />
            <div style={{ height: '100%' }} className="d-flex flex-row">
                <div className="d-flex flex-column gap-3 w-50">
                    {selectedCustomers &&
                        Object.keys(selectedCustomers)
                            .filter((customer) => selectedCustomers[customer].name?.toLowerCase().includes(search))
                            .map(
                                (customer) =>
                                    allUserCustomers[customer] && (
                                        <CCard
                                            className={`px-3 py-2 ${
                                                !initialSelectedCustomers[customer] ||
                                                selectedCustomers[customer].setForAllUsers
                                                    ? 'border-warning'
                                                    : ''
                                            }`}
                                            key={customer}
                                        >
                                            <div
                                                style={{ fontWeight: 'bold' }}
                                                className="d-flex align-items-center justify-content-between"
                                            >
                                                {allUserCustomers[customer].name}
                                                <CFormSwitch
                                                    style={{ cursor: 'pointer' }}
                                                    checked={!!selectedCustomers[customer]}
                                                    onChange={() => removeCustomer(customer)}
                                                />
                                            </div>
                                            <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                {customer}
                                            </p>
                                            {userArray.length > 1 && !selectedCustomers[customer].setForAllUsers && (
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <CTooltip
                                                        placement="left"
                                                        content={Object.keys(
                                                            selectedCustomers[customer].activeUsers
                                                        ).join(',')}
                                                    >
                                                        <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                            {`Active for ${
                                                                Object.keys(selectedCustomers[customer].activeUsers)
                                                                    .length
                                                            } user(s)`}
                                                        </p>
                                                    </CTooltip>
                                                    {Object.keys(selectedCustomers[customer].activeUsers).length <
                                                        userArray.length && (
                                                        <CButton
                                                            onClick={() => addCustomer(customer)}
                                                            color="secondary"
                                                            size="sm"
                                                        >
                                                            Set for all
                                                        </CButton>
                                                    )}
                                                </div>
                                            )}
                                        </CCard>
                                    )
                            )}
                </div>
                <div className="mx-3" style={{ width: '1px', height: '100%', background: 'rgba(0, 0, 21, 0.125)' }} />
                <div className="d-flex flex-column gap-3 w-50">
                    {allUserCustomers &&
                        Object.keys(allUserCustomers)
                            .filter((customer) => allUserCustomers[customer].name?.toLowerCase().includes(search))
                            .map(
                                (customer) =>
                                    !selectedCustomers[customer] && (
                                        <CCard
                                            className={`px-3 py-2 ${
                                                initialSelectedCustomers[customer] ? 'border-warning' : ''
                                            }`}
                                            key={customer}
                                        >
                                            <div
                                                style={{ fontWeight: 'bold' }}
                                                className="d-flex align-items-center justify-content-between"
                                            >
                                                {allUserCustomers[customer].name}
                                                <CFormSwitch
                                                    style={{ cursor: 'pointer' }}
                                                    checked={false}
                                                    onChange={() => addCustomer(customer)}
                                                />
                                            </div>
                                            <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                {customer}
                                            </p>
                                        </CCard>
                                    )
                            )}
                </div>
            </div>
        </>
    );
};

export default EditCustomers;
