import { useContext, useEffect, useState } from 'react';
import {
    CAccordion,
    CAccordionHeader,
    CAccordionItem,
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CPagination,
    CPaginationItem,
    CRow,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import PatientSingleMap from './PatientSingleMap';
import PatientContext from '@context/patient/PatientContext';
import DcPatientContext from '@context/dcpatient/DcPatientContext';
import AWSContext from '@context/AWSContext';
import AlertContext from '@context/alert/AlertContext';
import Alert from '../../components/Alert';
import { getHealthProfileData } from '@utils/helpers';
import { usePatientsStore } from '../../zustandStore';
import { stickySearchStyle } from '../../styles';

const Patients = () => {
    const { t } = useTranslation(['common']);
    const [sync, setSync] = useState(false);
    const [syncID] = useState();
    const [firstTimeLoad, setFirstTimeLoad] = useState(false);
    const [firstTimeApiCall, setFirstTimeApiCall] = useState(false);
    const patientContext = useContext(PatientContext);
    const dcPatientContext = useContext(DcPatientContext);
    const awsContext = useContext(AWSContext);
    const alertContext = useContext(AlertContext);
    const {
        getAllPatients,
        patients,
        getPatientHealthInsuranceById,
        syncPatientData,
        successMessage,
        error,
        clearError,
        setToDefault,
    } = patientContext;
    const { getDcPatientMappingByIds } = dcPatientContext;
    const { userToken } = awsContext;
    const { setAlert } = alertContext;
    const [patientUserId, setPatientUserId] = useState('');
    const [search, setSearch] = useState('');
    const [showAmount, setShowAmount] = useState(50);

    const syncDataOfPatients = () => {
        syncPatientData(userToken, syncID);
    };

    const showMorePatients = () => {
        if (patients.length >= showAmount + 50) setShowAmount(showAmount + 50);
        else setShowAmount(patients.length);
    };

    const [activeItem] = useState(0);

    const loadHealthProfile = async (id) => {
        try {
            const response = await getHealthProfileData(userToken, id);
            if (response.status === 200) {
                usePatientsStore.setState({
                    currentPatientHealthProfile: response.data,
                });
            }
        } catch (err) {
            console.debug(err);
        }
        usePatientsStore.setState({ loadingHealthProfile: false });
    };

    useEffect(() => {
        if (patients.length === 0) {
            if (firstTimeApiCall === false) {
                getAllPatients(userToken);
                setFirstTimeApiCall(true);
            }
        }
        if (error !== null) {
            if (patients.length === 0) {
                setAlert(error, 'danger');
                setFirstTimeLoad(true);
            } else {
                setAlert(error, 'danger');
                clearError();
            }
        }

        if (successMessage !== null) {
            setAlert(successMessage, 'success');
            setFirstTimeLoad(true);
            clearError();
        }
    }, [successMessage, error]);

    useEffect(() => {
        return () => {
            setToDefault();
        };
    }, []);

    return (
        <>
            <CContainer style={stickySearchStyle}>
                <div className="mb-3">
                    <CFormInput
                        style={{ width: '15rem' }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        id="search"
                        placeholder={`${t('Search')}`}
                    />
                </div>
                <Alert />
            </CContainer>
            <CContainer>
                {patients.length !== 0 ? (
                    <CRow xs={{ gutterY: 2 }}>
                        <CAccordion activeItemKey={activeItem}>
                            <InfiniteScroll
                                next={showMorePatients}
                                hasMore={patients.length > showAmount}
                                loader={null}
                                dataLength={showAmount}
                            >
                                {patients
                                    .filter((item) => {
                                        let includesSearch = true;
                                        search.split(' ').forEach((value) => {
                                            if (
                                                !(
                                                    item.email?.includes(value) ||
                                                    item.name?.first.includes(value) ||
                                                    item.name?.last.includes(value)
                                                )
                                            )
                                                includesSearch = false;
                                        });
                                        return includesSearch;
                                    })
                                    .slice(0, showAmount)
                                    .map((item, index) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <CAccordionItem key={item.id} itemKey={index}>
                                            <CAccordionHeader
                                                onClick={async () => {
                                                    usePatientsStore.setState({ currentPatientHealthProfile: null });
                                                    if (item.id !== patientUserId) {
                                                        setPatientUserId(item.id);
                                                        usePatientsStore.setState({ loadingHealthProfile: true });
                                                        await getDcPatientMappingByIds(userToken, item.id);
                                                        await getPatientHealthInsuranceById(userToken, item.id);
                                                        await loadHealthProfile(item.id);
                                                    }
                                                }}
                                            >
                                                {`${item.name.first} ${item.name.last}`}{' '}
                                                <span style={{ marginLeft: 10, fontSize: 12 }}> ({item.id}) </span>
                                            </CAccordionHeader>
                                            {item.id === patientUserId && (
                                                <PatientSingleMap key={item.id} item={item} index={index} />
                                            )}
                                        </CAccordionItem>
                                    ))}
                            </InfiniteScroll>
                        </CAccordion>
                    </CRow>
                ) : (
                    <CCol lg={12} md={12} sm={12} xs={12}>
                        {firstTimeLoad === false ? (
                            <CCard>
                                <CCardBody>
                                    <div className="text-center">
                                        <div className="spinner-grow text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                        ) : (
                            <CCard>
                                <CCardBody>
                                    <div className="text-center">
                                        <h3>{t('No Data Found')}</h3>
                                    </div>
                                </CCardBody>
                            </CCard>
                        )}
                    </CCol>
                )}
            </CContainer>

            <CContainer>
                <CRow xs={{ gutterY: 2 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: 50,
                        }}
                    >
                        <CPagination size="sm" aria-label="Pagination">
                            <CPaginationItem>Previous</CPaginationItem>
                            <CPaginationItem>1</CPaginationItem>
                            <CPaginationItem>2</CPaginationItem>
                            <CPaginationItem>3</CPaginationItem>
                            <CPaginationItem>4</CPaginationItem>
                            <CPaginationItem>5</CPaginationItem>
                            <CPaginationItem>6</CPaginationItem>
                            <CPaginationItem>Next</CPaginationItem>
                        </CPagination>
                    </div>
                </CRow>
            </CContainer>
            <CModal visible={sync} onClose={() => setSync(false)}>
                <CModalHeader onClose={() => setSync(false)}>
                    <CModalTitle>{t('Sync Data')}</CModalTitle>
                </CModalHeader>

                <CModalBody style={{ paddingBottom: 200 }}>
                    <p>{t('Are you sure to sync data?')}</p>
                </CModalBody>

                <CModalFooter>
                    <CButton color="secondary" onClick={() => setSync(false)}>
                        {t('No')}
                    </CButton>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setSync(false);
                            syncDataOfPatients();
                        }}
                    >
                        {t('Yes')}
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default Patients;
