import './styles.css';

const SimpleButton = ({
    children,
    icon = null,
    iconAlt = '',
    fullWidth = false,
    disabled = false,
    customWidth = null,
    active = false,
    onClick = () => {},
    style = {},
}) => {
    return (
        <button
            className="simple-button"
            onClick={onClick}
            disabled={disabled}
            style={{
                width: fullWidth ? '100%' : customWidth || 'auto',
                border: active ? '2px solid #FFC877' : '1px solid #ababab',
                ...style,
            }}
        >
            {children}
            {icon && <img className="simple-button-icon" src={icon} alt={iconAlt} />}
        </button>
    );
};

export default SimpleButton;
