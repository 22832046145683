import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    CAccordionBody,
    CButton,
    CCard,
    CCardBody,
    CCardText,
    CCardTitle,
    CCol,
    CContainer,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';

import PatientBasicInfoModal from './PatientBasicInfoModal';
import PatientAccountInfoModal from './PatientAccountInfoModal';
import PatientHealthInfoModal from './PatientHealthInfoModal';
import PatientAddressInfoModal from './PatientAddressInfoModal';
import PatientLinkDataModal from './PatientLinkDataModal';
import PatientContext from '@context/patient/PatientContext';
import DcPatientContext from '@context/dcpatient/DcPatientContext';
import AWSContext from '@context/AWSContext';
import { buttonBarStyle, buttonsContainer, cardContentStyle, cardStyle, cardTitleStyle } from '../../styles';
import { useLocationStore, usePatientsStore } from '../../zustandStore';

const PatientSingleMap = ({ item }) => {
    const history = useHistory();
    const { t } = useTranslation(['common']);
    const [edit, setEdit] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [sync, setSync] = useState(false);
    const [syncID, setSyncID] = useState();
    const [editItem, setEditItem] = useState(null);
    const patientContext = useContext(PatientContext);
    const dcPatientContext = useContext(DcPatientContext);
    const awsContext = useContext(AWSContext);
    const {
        getAllPatients,
        getAccountDetails,
        patientAccountDetails,
        updatePatientAccountInfo,
        updatePatientBasicInfo,
        updatePatientAddressesById,
        updatePatientHealthInsuranceInfo,
        getSinglePatientRecord,
        singlePatientRecord,
        getPatientHealthInsuranceById,
        patientHealthInsuranceDetails,
        getPatientAddressById,
        patientAddressDetails,
        syncPatientData,
        clearRecords,
    } = patientContext;
    const { dcPatientsIds, getDcPatientById } = dcPatientContext;
    const { userToken } = awsContext;
    const currentPatientHealthProfile = usePatientsStore((state) => state.currentPatientHealthProfile);
    const loadingHealthProfile = usePatientsStore((state) => state.loadingHealthProfile);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const [bodyData, setBodyData] = useState(null);

    useEffect(() => {
        if (!currentPatientHealthProfile) return;
        const body = currentPatientHealthProfile.category_data?.find((entry) => entry.category === 'body');
        if (body) setBodyData(body.data);
    }, [currentPatientHealthProfile]);

    const [patientAccountInfo, setPatientAccountInfo] = useState({
        privacy_policy_status: '',
        status: '',
        terms_and_conditions_status: '',
        use_of_analytics_status: '',
        user_id: '',
        verification_status: '',
        locale: {
            language: '',
            country: '',
        },
    });
    const [patientBasicInfo, setPatientBasicInfo] = useState({
        name: {
            first: '',
            last: '',
        },
        gender: '',
        date_of_birth: '',
    });

    const [patientHealthInsuranceInfo, setPatientHealthInsuranceInfo] = useState({
        insurance_id: '',
        user_id: '',
        insurance_company: '',
        insurance_type: '',
        insurance_company_id: '',
        insurance_valid_until: '',
        insurance_status: '',
    });
    const [patientAddressInfo, setPatientAddressInfo] = useState({
        user_id: '',
        street: '',
        zip: '',
        city: '',
        country: {
            code: '',
            name: '',
        },
    });

    const pull_data_from_patient_basic = (data) => {
        setPatientBasicInfo(data);
    };

    const pull_data_from_patient_account = (data) => {
        setPatientAccountInfo(data);
    };

    const pull_data_from_patient_health = (data) => {
        setPatientHealthInsuranceInfo(data);
    };
    const pull_data_from_patient_address = (data) => {
        setPatientAddressInfo(data);
    };
    const shiftPageForPatientView = (id) => {
        getDcPatientById(userToken, id, selectedLocation.instanceId);
        history.push('/dc-patient-view');
    };

    const editPatientAccountInfo = async () => {
        delete patientBasicInfo.meta;
        delete patientBasicInfo.email;
        delete patientBasicInfo.phone;
        delete patientBasicInfo.title;
        const { id } = patientBasicInfo;
        delete patientBasicInfo.id;
        if (!(JSON.stringify(patientBasicInfo) === '{}')) {
            updatePatientBasicInfo(userToken, id, patientBasicInfo);
        }
        delete patientAccountInfo.meta;
        if (!(JSON.stringify(patientAccountInfo) === '{}')) {
            updatePatientAccountInfo(userToken, patientAccountInfo.user_id, patientAccountInfo);
        }
        if (!(JSON.stringify(patientAddressInfo) === '{}')) {
            updatePatientAddressesById(userToken, patientAddressInfo.id, patientAddressInfo);
        }

        delete patientHealthInsuranceInfo.meta;
        if (!(JSON.stringify(patientHealthInsuranceInfo) === '{}')) {
            await updatePatientHealthInsuranceInfo(
                userToken,
                patientHealthInsuranceInfo.user_id,
                patientHealthInsuranceInfo
            );
        }

        getAllPatients(userToken);
    };

    const syncDataOfPatients = () => {
        syncPatientData(userToken, syncID);
    };

    return (
        <>
            <CAccordionBody>
                <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                    <CRow xs={{ gutterY: 2 }}>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard style={{ marginBottom: '1rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}> {t('Email')} </CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {' '}
                                        {typeof item.email !== 'undefined' ? item.email : '-'}{' '}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard style={{ marginBottom: '1rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Date Of Birth')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {typeof item.date_of_birth !== 'undefined' ? item.date_of_birth : '-'}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard style={{ marginBottom: '1rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Dc Patient IDs')}</CCardText>
                                    {dcPatientsIds !== null && (JSON.stringify(dcPatientsIds) === '{}') !== true ? (
                                        <CCardTitle style={cardContentStyle}>
                                            {dcPatientsIds?.dc_user_ids && dcPatientsIds?.dc_user_ids.length > 0
                                                ? dcPatientsIds?.dc_user_ids?.map((id) => (
                                                      <div key={id} onClick={() => shiftPageForPatientView(id)}>
                                                          <p style={{ margin: 0, cursor: 'pointer' }}>{id}</p>
                                                      </div>
                                                  ))
                                                : '-'}
                                        </CCardTitle>
                                    ) : (
                                        '-'
                                    )}
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard style={{ marginBottom: '1rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Gender')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {typeof item.gender !== 'undefined' ? item.gender : '-'}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard style={{ marginBottom: '1rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Weight')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {!loadingHealthProfile && bodyData
                                            ? `${bodyData.weight.value}${bodyData.weight.units}`
                                            : '-'}
                                        {loadingHealthProfile && (
                                            <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard style={{ marginBottom: '1rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Height')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {!loadingHealthProfile && bodyData
                                            ? `${bodyData.height.value}${bodyData.height.units}`
                                            : '-'}
                                        {loadingHealthProfile && (
                                            <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Blood Type')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {!loadingHealthProfile ? (
                                            bodyData?.blood_type || '-'
                                        ) : (
                                            <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Cholesterol Level')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {!loadingHealthProfile ? (
                                            bodyData?.cholesterol_level || '-'
                                        ) : (
                                            <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={4} md={4} sm={12} xs={12}>
                            <CCard>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Blood Pressure')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>
                                        {!loadingHealthProfile &&
                                        bodyData?.blood_pressure &&
                                        (bodyData.blood_pressure.systolic || bodyData.blood_pressure.diastolic)
                                            ? `${bodyData.blood_pressure.systolic || '-'}/${
                                                  bodyData.blood_pressure.diastolic || '-'
                                              }`
                                            : '-'}
                                        {loadingHealthProfile && (
                                            <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
                <CContainer style={{ padding: 0, paddingTop: 10 }}>
                    <div style={buttonBarStyle}>
                        <div style={buttonsContainer}>
                            <CCol sm="auto" xs={12}>
                                <CButton color="secondary">{t('Reset')}</CButton>
                            </CCol>

                            <CCol sm="auto" xs={12}>
                                <CButton color="secondary">{t('Deactivate')}</CButton>
                            </CCol>

                            <CCol sm="auto" xs={12}>
                                <CButton
                                    onClick={() => {
                                        setEditItem(item);
                                        setEdit(true);
                                        getAccountDetails(userToken, item.id);
                                        // getAppointmentDetailsFunctionIfNeeded
                                        getSinglePatientRecord(userToken, item.id);
                                        getPatientHealthInsuranceById(userToken, item.id);
                                        getPatientAddressById(userToken, item.id);
                                    }}
                                    color="secondary"
                                >
                                    {t('Edit')}
                                </CButton>
                            </CCol>
                            <CCol sm="auto" xs={12}>
                                <CButton
                                    onClick={() => {
                                        // setConfirm(true)
                                        setSyncID(item.id);
                                        setSync(true);
                                    }}
                                    color="secondary"
                                >
                                    {t('Sync Data')}
                                </CButton>
                            </CCol>
                            <CCol sm="auto" xs={12}>
                                <PatientLinkDataModal email={item.email} />
                            </CCol>
                        </div>
                        <div style={buttonsContainer}>
                            <CButton style={{ color: 'white' }} color="primary">
                                {t('Medical Records')}
                            </CButton>
                            <CButton style={{ color: 'white' }} color="primary">
                                {t('Patient Records')}
                            </CButton>
                        </div>
                    </div>
                </CContainer>
            </CAccordionBody>
            <CModal
                scrollable
                visible={edit}
                onClose={() => {
                    setEdit(false);
                    clearRecords();
                }}
            >
                <CModalHeader>
                    <CModalTitle>
                        {t('Edit')} {editItem?.patientName}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody lg={12} md={12} sm={12} xs={12}>
                    {singlePatientRecord !== null &&
                    patientAccountDetails !== null &&
                    patientHealthInsuranceDetails !== null &&
                    patientAddressDetails !== null ? (
                        <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                            <CRow xs={{ gutterY: 2 }}>
                                {singlePatientRecord !== null &&
                                (JSON.stringify(singlePatientRecord) === '{}') !== true ? (
                                    <PatientBasicInfoModal func={pull_data_from_patient_basic} />
                                ) : (
                                    'no patient'
                                )}
                                {patientAccountDetails !== null &&
                                (JSON.stringify(patientAccountDetails) === '{}') !== true ? (
                                    <PatientAccountInfoModal func={pull_data_from_patient_account} />
                                ) : (
                                    ''
                                )}
                                {patientHealthInsuranceDetails !== null &&
                                (JSON.stringify(patientHealthInsuranceDetails) === '{}') !== true ? (
                                    <PatientHealthInfoModal func={pull_data_from_patient_health} />
                                ) : (
                                    ''
                                )}
                                {patientAddressDetails !== null &&
                                (JSON.stringify(patientAddressDetails) === '{}') !== true ? (
                                    <PatientAddressInfoModal func={pull_data_from_patient_address} />
                                ) : (
                                    ''
                                )}
                            </CRow>
                        </CContainer>
                    ) : (
                        <div className="text-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </CModalBody>

                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setEdit(false);
                            clearRecords();
                        }}
                    >
                        {t('Close')}
                    </CButton>
                    <CButton
                        onClick={() => {
                            setConfirm(true);
                        }}
                        color="secondary"
                    >
                        {t('Save Changes')}
                    </CButton>
                </CModalFooter>
            </CModal>
            <CModal visible={confirm} onClose={() => setConfirm(false)}>
                <CModalHeader onClose={() => setConfirm(false)}>
                    <CModalTitle>Save Changes</CModalTitle>
                </CModalHeader>

                <CModalBody style={{ paddingBottom: 200 }}>
                    <p>Are you sure you want to do these changes?</p>
                </CModalBody>

                <CModalFooter>
                    <CButton color="secondary" onClick={() => setConfirm(false)}>
                        {t('No')}
                    </CButton>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setEdit(false);
                            setConfirm(false);
                            editPatientAccountInfo();
                        }}
                    >
                        {t('Yes')}
                    </CButton>
                </CModalFooter>
            </CModal>

            <CModal visible={sync} onClose={() => setSync(false)}>
                <CModalHeader onClose={() => setSync(false)}>
                    <CModalTitle>{t('Sync Data')}</CModalTitle>
                </CModalHeader>

                <CModalBody style={{ paddingBottom: 200 }}>
                    <p>{t('Are you sure to sync data?')}</p>
                </CModalBody>

                <CModalFooter>
                    <CButton color="secondary" onClick={() => setSync(false)}>
                        {t('No')}
                    </CButton>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setSync(false);
                            syncDataOfPatients();
                        }}
                    >
                        {t('Yes')}
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default PatientSingleMap;
