import { CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormSwitch, CFormTextarea } from '@coreui/react';

export const FormDropDownList = ({ name, data, label, value, onChange = () => {}, id, valid, disabled }) => {
    return (
        <>
            <CFormLabel
                className="paragraph-text"
                id={name}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </CFormLabel>
            <CFormSelect
                className="paragraph-text"
                aria-labelledby={name}
                name={name}
                value={value}
                valid={valid}
                id={id}
                disabled={disabled}
                options={data}
                onChange={onChange}
            />
        </>
    );
};

export const FormRadioCheckList = ({ name, value, onChange = () => {}, data, label, valid, disabled }) => {
    return (
        <>
            {label !== '' ? (
                <CFormLabel className="paragraph-text" editorValid={valid} editorDisabled={disabled}>
                    {label}
                </CFormLabel>
            ) : (
                ''
            )}
            {data?.map((h, index) => (
                <CFormCheck
                    id={data[index]?.id}
                    className="paragraph-text"
                    type="radio"
                    name={name}
                    value={data[index].value}
                    defaultChecked={data[index].value === value}
                    label={`${data[index].label}`}
                    onChange={onChange}
                />
            ))}
        </>
    );
};

export const FormCheckboxList = ({ name, value, onChange = () => {}, data, checkedValue }) => {
    return data?.map((h, index) => (
        <CFormCheck
            className="paragraph-text"
            id={data[index].id}
            name={name}
            value={data[index].value}
            defaultChecked={checkedValue}
            checked={value?.some((entry) => entry?.id === data[index]?.id)}
            label={`${data[index].label}`}
            onChange={onChange}
        />
    ));
};

export const FormInputText = ({ name, value, onChange = () => {}, label, valid, disabled }) => {
    return (
        <>
            <CFormLabel className="paragraph-text" id={name} editorValid={valid} editorDisabled={disabled}>
                {label}
            </CFormLabel>
            <CFormInput
                className="paragraph-text"
                aria-labelledby={name}
                name={name}
                value={value}
                valid={valid}
                disabled={disabled}
                onChange={onChange}
            />
        </>
    );
};

export const FormTextArea = ({ name, rows, data, value, onChange = () => {}, label, valid, disabled }) => {
    return (
        <>
            {label !== '' ? (
                <CFormLabel className="paragraph-text" editorValid={valid} editorDisabled={disabled}>
                    {label}
                </CFormLabel>
            ) : (
                ''
            )}
            <CFormTextarea
                className="paragraph-text"
                rows={rows}
                text={data}
                name={name}
                value={value}
                onChange={onChange}
            />
        </>
    );
};

export const FormSwitch = ({ name, value, onChange = () => {}, label }) => {
    return (
        <CFormSwitch
            className="paragraph-text"
            label={label}
            name={name}
            value={value}
            checked={value}
            onChange={onChange}
            id="formSwitchCheckDefault"
        />
    );
};
