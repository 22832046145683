import { CButton, CCard, CFormInput, CFormSwitch, CTooltip } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const EditScopes = ({
    search,
    setSearch,
    selectedScopes,
    allUserScopes,
    initialSelectedScopes,
    setSelectedScopes,
    userArray,
}) => {
    const { t } = useTranslation();

    const removeScope = (scope) => {
        const newSelectedScopes = { ...selectedScopes };
        delete newSelectedScopes[scope];

        setSelectedScopes(newSelectedScopes);
    };

    const addScope = (scope) => {
        const newSelectedScopes = { ...selectedScopes };
        newSelectedScopes[scope] = {
            ...(selectedScopes[scope] ?? allUserScopes[scope]),
            setForAllUsers: true,
        };

        setSelectedScopes(newSelectedScopes);
    };

    return (
        <>
            <CFormInput placeholder={t('Search')} value={search} onChange={(e) => setSearch(e.target.value)} />
            <div style={{ height: '100%' }} className="d-flex flex-row">
                <div className="d-flex flex-column gap-3 w-50">
                    {selectedScopes &&
                        Object.keys(selectedScopes)
                            .filter((scope) => scope.toLowerCase().includes(search))
                            .map(
                                (scope) =>
                                    allUserScopes[scope] && (
                                        <CCard
                                            className={`px-3 py-2 ${
                                                !initialSelectedScopes[scope] || selectedScopes[scope].setForAllUsers
                                                    ? 'border-warning'
                                                    : ''
                                            }`}
                                            key={scope}
                                        >
                                            <div
                                                style={{ fontWeight: 'bold' }}
                                                className="d-flex align-items-center justify-content-between"
                                            >
                                                {scope}
                                                <CFormSwitch
                                                    style={{ cursor: 'pointer' }}
                                                    checked={!!selectedScopes[scope]}
                                                    onChange={() => removeScope(scope)}
                                                />
                                            </div>
                                            <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                {selectedScopes[scope].description}
                                            </p>
                                            {userArray.length > 1 && !selectedScopes[scope].setForAllUsers && (
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <CTooltip
                                                        placement="left"
                                                        content={Object.keys(selectedScopes[scope].activeUsers).join(
                                                            ','
                                                        )}
                                                    >
                                                        <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                            {`Active for ${
                                                                Object.keys(selectedScopes[scope].activeUsers).length
                                                            } user(s)`}
                                                        </p>
                                                    </CTooltip>
                                                    {Object.keys(selectedScopes[scope].activeUsers).length <
                                                        userArray.length && (
                                                        <CButton
                                                            onClick={() => addScope(scope)}
                                                            color="secondary"
                                                            size="sm"
                                                        >
                                                            Set for all
                                                        </CButton>
                                                    )}
                                                </div>
                                            )}
                                        </CCard>
                                    )
                            )}
                </div>
                <div className="mx-3" style={{ width: '1px', height: '100%', background: 'rgba(0, 0, 21, 0.125)' }} />
                <div className="d-flex flex-column gap-3 w-50">
                    {allUserScopes &&
                        Object.keys(allUserScopes)
                            .filter((scope) => scope.toLowerCase().includes(search))
                            .map(
                                (scope) =>
                                    !selectedScopes[scope] && (
                                        <CCard
                                            className={`px-3 py-2 ${
                                                initialSelectedScopes[scope] ? 'border-warning' : ''
                                            }`}
                                            key={scope}
                                        >
                                            <div
                                                style={{ fontWeight: 'bold' }}
                                                className="d-flex align-items-center justify-content-between"
                                            >
                                                {scope}
                                                <CFormSwitch
                                                    style={{ cursor: 'pointer' }}
                                                    checked={false}
                                                    onChange={() => addScope(scope)}
                                                />
                                            </div>
                                            <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                {allUserScopes[scope].description}
                                            </p>
                                        </CCard>
                                    )
                            )}
                </div>
            </div>
        </>
    );
};

export default EditScopes;
