/* eslint-disable import/no-anonymous-default-export */
import {
    CLEAR_ERRORS,
    GET_ALL_DC_PATIENTS_FAIL,
    GET_ALL_DC_PATIENTS_SUCCESS,
    GET_DC_PATIENT_BY_ID_FAIL,
    GET_DC_PATIENT_BY_ID_SUCCESS,
    GET_DC_PATIENT_MAPPINGS_FAIL,
    GET_DC_PATIENT_MAPPINGS_SUCCESS,
    GET_DC_PATIENTS_IDS_FAIL,
    GET_DC_PATIENTS_IDS_SUCCESS,
    SET_TO_DEFAULT,
} from '../../store/actions';

export default (state, action) => {
    switch (action.type) {
        case GET_ALL_DC_PATIENTS_SUCCESS:
            return {
                ...state,
                dcPatients: action.payload,
                successMessage: action.successMessage,
            };

        case GET_DC_PATIENT_MAPPINGS_SUCCESS:
            return {
                ...state,
                dcPatientMappings: action.payload,
            };

        case GET_DC_PATIENTS_IDS_SUCCESS:
            return {
                ...state,
                dcPatientsIds: action.payload,
            };

        case GET_DC_PATIENT_BY_ID_SUCCESS:
            return {
                ...state,
                singleDcPatientRecord: action.payload,
                successMessage: action.successMessage,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
            };
        case SET_TO_DEFAULT:
            return {
                ...state,
                dcPatients: [],
                error: null,
                successMessage: null,
            };
        case GET_ALL_DC_PATIENTS_FAIL:
        case GET_DC_PATIENTS_IDS_FAIL:
        case GET_DC_PATIENT_BY_ID_FAIL:
        case GET_DC_PATIENT_MAPPINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
