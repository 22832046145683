export const isAuthRequired = {
    '4.31.0': true,
    '4.31.1': true,
    '4.31.2': true,
    '4.31.3': true,
    '4.31.4': true,
    '4.31.5': true,
    '4.31.8': true,
};

export const AccessRequestStatus = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
};

export const AccessRequestTabs = {
    ACTIVE: 'ACTIVE',
    HISTORY: 'HISTORY',
};

export const EmployeeType = {
    HUB_EMPLOYEE: 'internal_hub-employee',
    HUB_MANAGER: 'internal_hub-manager',
};

export const accessRequestEmployeeTypesMap = {
    [EmployeeType.HUB_EMPLOYEE]: {
        key: EmployeeType.HUB_EMPLOYEE,
        name: 'Hub Employee',
    },
    [EmployeeType.HUB_MANAGER]: {
        key: EmployeeType.HUB_MANAGER,
        name: 'Hub Manager',
    },
};

export const QuestionType = {
    DROPDOWN: 'Dropdown',
    TEXTFIELD: 'Textfield',
    TEXT_AREA: 'Text Area',
    NUMBER_FIELD: 'Number Field',
    DATE_FIELD: 'Date Field',
    RADIO: 'Radio',
    MULTI_SELECT: 'Multi Select',
    LIST: 'List',
};

export const FilledFormsState = {
    ALL: 'all',
    SOME: 'some',
    NONE: 'none',
};

export const UserStatus = {
    KNOWN: 'known',
    UNKNOWN: 'unknown',
};

export const SharedFormStatus = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
};

export const FormTemplateStatus = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    ARCHIVED: 'archived',
};
