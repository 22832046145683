import { useMemo, useReducer } from 'react';

import {
    getAllPractitioner,
    getData,
    getFormTemplates,
    getUserById,
    syncProfessional,
    updateUserById,
} from '../../api/professional/ProfessionalCalls';
import ProfessionalReducer from './ProfessionalReducer';
import ProfessionalContext from './ProfessionalContext';
import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_PROFESSIONALS_FAIL,
    GET_ALL_PROFESSIONALS_REQUEST,
    GET_ALL_PROFESSIONALS_SUCCESS,
    GET_ONBOARDING_DATA_FAIL,
    GET_ONBOARDING_DATA_SUCCESS,
    GET_PROFESSIONAL_BY_ID_FAIL,
    GET_PROFESSIONAL_BY_ID_SUCCESS,
    GET_PROFESSIONAL_TEMPLATE_ANA_SUCCESS,
    GET_PROFESSIONAL_TEMPLATE_FAIL,
    GET_PROFESSIONAL_TEMPLATE_REQUEST,
    GET_PROFESSIONAL_TEMPLATE_SUCCESS,
    SET_TO_DEFAULT,
    SYNC_PROFESSIONAL_DATA_FAIL,
    SYNC_PROFESSIONAL_DATA_SUCCESS,
    UPDATE_PROFESSIONAL_BY_ID_FAIL,
    UPDATE_PROFESSIONAL_BY_ID_SUCCESS,
} from '../../store/actions';

const ProfessionalState = ({ children }) => {
    const initialState = {
        professionals: [],
        formsTemplates: [],
        onBoardingDetails: null,
        singleProfessionalRecord: null,
        error: null,
        successMessage: null,
        loading: false,
    };
    const [state, dispatch] = useReducer(ProfessionalReducer, initialState);
    // getPatientsCall
    const getProfessionals = async (userToken) => {
        dispatch({
            type: GET_ALL_PROFESSIONALS_REQUEST,
        });

        try {
            const response = await getAllPractitioner(userToken);
            console.debug(response);
            dispatch({
                type: GET_ALL_PROFESSIONALS_SUCCESS,
                payload: response.data.data,
                successMessage: response.data.data.length !== 0 ? 'Record Found!' : 'Record not Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_PROFESSIONALS_FAIL,
                payload: error.message,
            });
        }
    };

    // getOnBoardingData
    const getOnBoardingData = async (userToken, id) => {
        try {
            const response = await getData(userToken, id);
            dispatch({
                type: GET_ONBOARDING_DATA_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: GET_ONBOARDING_DATA_FAIL,
                payload: error.message,
            });
        }
    };

    // getProfessionalById
    const getProfessionalById = async (userToken, id) => {
        try {
            const response = await getUserById(userToken, id);
            dispatch({
                type: GET_PROFESSIONAL_BY_ID_SUCCESS,
                payload: response.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_PROFESSIONAL_BY_ID_FAIL,
                payload: error.message,
            });
        }
    };

    // updateProfessionalById
    const updateProfessionalById = async (userToken, id, professionalBasicInfo) => {
        try {
            const response = await updateUserById(userToken, id, professionalBasicInfo);
            dispatch({
                type: UPDATE_PROFESSIONAL_BY_ID_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PROFESSIONAL_BY_ID_FAIL,
                payload: error.message,
            });
        }
    };

    // syncProfessionalData
    const syncProfessionalData = async (userToken, id) => {
        try {
            const response = await syncProfessional(userToken, id);
            dispatch({
                type: SYNC_PROFESSIONAL_DATA_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: SYNC_PROFESSIONAL_DATA_FAIL,
                payload: error.message,
            });
        }
    };

    // getFormsTemplate
    const getFormsTemplatesById = async (userToken, id, isAnamnesis) => {
        console.debug('getFormsTemplatesById calling');
        dispatch({
            type: SET_TO_DEFAULT,
        });
        dispatch({
            type: GET_PROFESSIONAL_TEMPLATE_REQUEST,
        });

        try {
            const response = await getFormTemplates(userToken, id);
            console.debug('response from api', response);
            if (!isAnamnesis) {
                dispatch({
                    type: GET_PROFESSIONAL_TEMPLATE_SUCCESS,
                    payload: response.data.data,
                    successMessage: 'Record Found!',
                });
            } else {
                dispatch({
                    type: GET_PROFESSIONAL_TEMPLATE_ANA_SUCCESS,
                    payload: response.data.data,
                    successMessage: 'Record Found!',
                });
            }
        } catch (error) {
            dispatch({
                type: GET_PROFESSIONAL_TEMPLATE_FAIL,
                payload: error.message,
            });
        }
    };

    const clearRecords = () => {
        dispatch({ type: CLEAR_OBJECTS });
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            professionals: state.professionals,
            formsTemplates: state.formsTemplates,
            onBoardingDetails: state.onBoardingDetails,
            singleProfessionalRecord: state.singleProfessionalRecord,
            successMessage: state.successMessage,
            error: state.error,
            loading: state.loading,
            getProfessionals: getProfessionals,
            getOnBoardingData: getOnBoardingData,
            getProfessionalById: getProfessionalById,
            updateProfessionalById: updateProfessionalById,
            syncProfessionalData: syncProfessionalData,
            getFormsTemplatesById: getFormsTemplatesById,
            clearRecords: clearRecords,
            clearError: clearError,
            setToDefault: setToDefault,
        }),
        [state]
    );

    return <ProfessionalContext.Provider value={value}>{children}</ProfessionalContext.Provider>;
};
export default ProfessionalState;
