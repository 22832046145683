import { createStore } from 'redux';

const initialState = {
    sidebarShow: true,
};

// eslint-disable-next-line default-param-last
const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        default:
            return state;
    }
};

const store = createStore(changeState);
export default store;
