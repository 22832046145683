import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

const usePollingData = (key, fetcher, shouldStopPollingCondition, initialInterval = 10000, maxAttempts = 10) => {
    const [isPolling, setIsPolling] = useState(true);
    const attemptCountRef = useRef(0);

    const { data } = useSWR(key, fetcher, {
        refreshInterval: isPolling ? initialInterval : 0,
    });

    useEffect(() => {
        if (!data) return;

        attemptCountRef.current += 1;

        const shouldStopPolling = shouldStopPollingCondition(data);
        const reachedMaxAttempts = attemptCountRef.current >= maxAttempts;

        setIsPolling(!shouldStopPolling && !reachedMaxAttempts);
    }, [data, shouldStopPollingCondition, maxAttempts]);

    return { data: data };
};

export default usePollingData;
