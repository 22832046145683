import { CChart } from '@coreui/react-chartjs';

const Chart = ({ chartType, labels, label, data, backgroundColor = ['#FFC877'], title, subTitle }) => {
    return (
        <CChart
            type={chartType}
            data={{
                labels: labels,
                datasets: [
                    {
                        label: label,
                        backgroundColor: backgroundColor,
                        data: data,
                    },
                ],
            }}
            options={{
                scales: {
                    y: {
                        beginAtZero: true,
                        max: Math.max(...data) + 10,
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const { dataIndex } = context;
                                return `${data[dataIndex]} ${subTitle || ''}`;
                            },
                            title: (context) => {
                                const labelIndex = context[0].dataIndex;
                                return `${labels[labelIndex]} ${title || ''}`;
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default Chart;
