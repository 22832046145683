const DetailsField = ({ name, children, alignStart = false }) => {
    return (
        <div
            className="m-0 p-0"
            style={{ display: 'flex', alignItems: alignStart ? 'start' : 'center', fontSize: '0.9rem' }}
        >
            <p className="m-0 p-0" style={{ width: '9rem', fontWeight: 300 }}>
                {name}
            </p>
            {children}
        </div>
    );
};

export default DetailsField;
