import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle } from '@coreui/icons';
import { useSelector } from 'react-redux';

import { useLocationStore, usePatientCallSystemStore } from '../../zustandStore';

const PrinterDropdown = ({ children }) => {
    const sidebarShow = useSelector((state) => state.sidebarShow);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const selectedPrinter = usePatientCallSystemStore((state) => state.selectedPrinter);

    return (
        <CDropdown size={!sidebarShow ? 'md' : 'sm'} variant="btn-group">
            {children}
            <CDropdownToggle color="secondary" split />
            <CDropdownMenu>
                {selectedLocation.config.pcs_printers.map((printer, index) => (
                    <CDropdownItem
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: 'pointer' }}
                        key={printer.name[0].text}
                        onClick={() =>
                            usePatientCallSystemStore.setState({
                                selectedPrinter: {
                                    ...selectedPrinter,
                                    [selectedLocation.key]: printer,
                                },
                            })
                        }
                    >
                        {printer.name[0].text}
                        {(selectedPrinter[selectedLocation.key]?.address?.[0]?.text === printer.address[0].text ||
                            (!selectedPrinter[selectedLocation.key] && index === 0)) && (
                            <CIcon className="text-primary" icon={cilCheckCircle} />
                        )}
                    </CDropdownItem>
                ))}
            </CDropdownMenu>
        </CDropdown>
    );
};

export default PrinterDropdown;
