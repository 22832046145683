import { CButton, CCard, CFormInput, CFormSwitch, CTooltip } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const EditGroups = ({
    search,
    setSearch,
    selectedGroups,
    initialSelectedGroups,
    allUserGroups,
    setSelectedGroups,
    userArray,
}) => {
    const { t } = useTranslation();

    const moveGroupToInactive = (group) => {
        const newSelectedGroups = { ...selectedGroups };
        delete newSelectedGroups[group];

        setSelectedGroups(newSelectedGroups);
    };

    const moveGroupToActive = (group) => {
        const newSelectedGroup = { ...selectedGroups };
        newSelectedGroup[group] = {
            ...(selectedGroups[group] ?? allUserGroups[group]),
            setForAllUsers: true,
        };

        setSelectedGroups(newSelectedGroup);
    };

    return (
        <>
            <CFormInput placeholder={t('Search')} value={search} onChange={(e) => setSearch(e.target.value)} />
            <div style={{ height: '100%' }} className="d-flex flex-row">
                <div className="d-flex flex-column gap-3 w-50">
                    {selectedGroups &&
                        Object.keys(selectedGroups)
                            .filter((group) => group.toLowerCase().includes(search))
                            .map((group) => (
                                <CCard
                                    className={`px-3 py-2 ${
                                        !initialSelectedGroups[group] || selectedGroups[group].setForAllUsers
                                            ? 'border-warning'
                                            : ''
                                    }`}
                                    style={{
                                        cursor: selectedGroups[group].disabled ? 'not-allowed' : 'default',
                                        opacity: selectedGroups[group].disabled ? '0.6' : 1,
                                    }}
                                    key={group}
                                >
                                    <div
                                        style={{ fontWeight: 'bold' }}
                                        className="d-flex align-items-center justify-content-between"
                                    >
                                        {group}
                                        <CFormSwitch
                                            disabled={selectedGroups[group].disabled}
                                            style={{
                                                cursor: selectedGroups[group].disabled ? 'not-allowed' : 'pointer',
                                            }}
                                            checked={!!selectedGroups[group]}
                                            onChange={() => moveGroupToInactive(group)}
                                        />
                                    </div>
                                    {userArray.length > 1 && !selectedGroups[group].setForAllUsers && (
                                        <div className="d-flex align-items-center justify-content-between">
                                            <CTooltip
                                                placement="left"
                                                content={Object.keys(selectedGroups[group].activeUsers).join(',')}
                                            >
                                                <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                                                    {`Active for ${
                                                        Object.keys(selectedGroups[group].activeUsers).length
                                                    } user(s)`}
                                                </p>
                                            </CTooltip>
                                            {!selectedGroups[group].disabled &&
                                                Object.keys(selectedGroups[group].activeUsers).length <
                                                    userArray.length && (
                                                    <CButton
                                                        onClick={() => moveGroupToActive(group)}
                                                        color="secondary"
                                                        size="sm"
                                                    >
                                                        Set for all
                                                    </CButton>
                                                )}
                                        </div>
                                    )}
                                </CCard>
                            ))}
                </div>
                <div className="mx-3" style={{ width: '1px', height: '100%', background: 'rgba(0, 0, 21, 0.125)' }} />
                <div className="d-flex flex-column gap-3 w-50">
                    {allUserGroups &&
                        Object.keys(allUserGroups)
                            .filter((group) => group.toLowerCase().includes(search))
                            .map(
                                (group) =>
                                    !selectedGroups[group] && (
                                        <CCard
                                            className={`px-3 py-2 ${
                                                initialSelectedGroups[group] ? 'border-warning' : ''
                                            }`}
                                            key={group}
                                        >
                                            <div
                                                style={{ fontWeight: 'bold' }}
                                                className="d-flex align-items-center justify-content-between"
                                            >
                                                {group}
                                                <CFormSwitch
                                                    style={{ cursor: 'pointer' }}
                                                    checked={false}
                                                    onChange={() => moveGroupToActive(group)}
                                                />
                                            </div>
                                        </CCard>
                                    )
                            )}
                </div>
            </div>
        </>
    );
};

export default EditGroups;
