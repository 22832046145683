import { Suspense } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import './scss/style.scss';
import MagicLinkPage from './views/pages/magiclink/MagicLinkPage';
import useThemeOverride from '@hooks/useThemeOverride';
import DefaultLayout from './layout/DefaultLayout';
import Login from './views/pages/login/Login';
import Page404 from './views/pages/page404/Page404';
import Page500 from './views/pages/page500/Page500';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

const Navigation = () => {
    const { user } = useAuth();
    useThemeOverride();

    return (
        <HashRouter>
            <Suspense fallback={loading}>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        render={(props) => {
                            if (user !== null) {
                                return <DefaultLayout {...props} />;
                            }
                            return <Login {...props} />;
                        }}
                    />
                    <Route
                        exact
                        path="/magic-link"
                        render={(props) => {
                            if (user !== null) {
                                return <DefaultLayout {...props} />;
                            }
                            return <MagicLinkPage {...props} />;
                        }}
                    />
                    <Route exact path="/404" render={(props) => <Page404 {...props} />} />
                    <Route exact path="/500" render={(props) => <Page500 {...props} />} />
                    <Route
                        path="/"
                        render={(props) => {
                            if (user !== null) {
                                return <DefaultLayout {...props} />;
                            }
                            return (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        state: { from: props.location },
                                    }}
                                />
                            );
                        }}
                    />
                </Switch>
            </Suspense>
        </HashRouter>
    );
};

export default Navigation;
