import { useTheme } from '@context/theme/ThemeContext';
import AppSidebar from '../components/AppSidebar';
import AppHeader from '../components/AppHeader';
import AppContent from '../components/AppContent';
import AppFooter from '../components/AppFooter';

const DefaultLayout = () => {
    const theme = useTheme();

    return (
        <div style={theme.vars}>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <AppContent />
                </div>
                <AppFooter />
            </div>
        </div>
    );
};

export default DefaultLayout;
