import { useTranslation } from 'react-i18next';

const EternoUserTag = ({ isEternoUser }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                background: isEternoUser ? '#FFC877' : '#F5F5F5',
                fontSize: '0.8rem',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.3rem',
            }}
        >
            {isEternoUser ? t('Eterno User') : t('No Eterno User')}
        </div>
    );
};

export default EternoUserTag;
