import { CSpinner } from '@coreui/react';

import { useTodaysAppointmentsStore } from '../../zustandStore';
import './loadingIndicatorStyles.css';

const LoadingIndicator = () => {
    const isTodaysAppointmentsLoading = useTodaysAppointmentsStore((state) => state.isTodaysAppointmentsLoading);
    const isCheckinDataLoading = useTodaysAppointmentsStore((state) => state.isCheckinDataLoading);
    const isPatientDataLoading = useTodaysAppointmentsStore((state) => state.isPatientDataLoading);

    const getText = () => {
        if (isTodaysAppointmentsLoading) return 'Heutige Termine werden geladen...';
        if (isCheckinDataLoading) return 'Check-In Daten werden geladen...';
        if (isPatientDataLoading) return 'Patientendaten werden geladen...';
        return 'Patientendaten werden geladen...';
    };

    return (
        <div
            className="loading-indicator"
            style={{
                pointerEvents:
                    !isTodaysAppointmentsLoading && !isCheckinDataLoading && !isPatientDataLoading ? 'none' : 'auto',
                opacity: !isTodaysAppointmentsLoading && !isCheckinDataLoading && !isPatientDataLoading ? 0 : 0.7,
            }}
        >
            <CSpinner size="sm" color="secondary" variant="grow" />
            <p className="m-0 p-0">{getText()}</p>
        </div>
    );
};

export default LoadingIndicator;
