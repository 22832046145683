import { useEffect, useState } from 'react';
import { CCol, CFormCheck } from '@coreui/react';
import { useTranslation } from 'react-i18next';

import {
    FormCheckboxList,
    FormInputText,
    FormRadioCheckList,
    FormSwitch,
    FormTextArea,
} from '../../components/forms/FormComponents';
import { usePrismicStore, useProfessionalsStore } from '../../zustandStore';

const TreatmentContractShort = ({ change, setChange, edited, formData, setFormData }) => {
    const { t } = useTranslation(['common']);
    const [appointmentTypes] = useState([]);
    const [serviceAppointments, setServiceAppointments] = useState([]);
    const [insuranceTypes] = useState([
        { value: 'PUBLIC', label: `${t('PUBLIC')}`, id: '1' },
        { value: 'PRIVATE', label: `${t('PRIVATE')}`, id: '2' },
        { value: 'SELF_PAYER', label: `${t('SELF_PAYER')}`, id: '3' },
    ]);
    const [checked, setChecked] = useState(false);
    const [checkedInsurance, setCheckedInsurance] = useState(false);
    let count = 1;
    const [firstTimeLoad, setFirstTimeLoad] = useState(false);
    const prismicAppointmentTypesData = usePrismicStore((state) => state.appointmentTypes);
    const welcomeTreatmentContract = usePrismicStore((state) => state.welcomeTreatmentContract);
    const cachedUserData = useProfessionalsStore((state) => state.cachedUserData);

    const consentText = () => {
        const para = welcomeTreatmentContract.contract_details_text.map((a) => {
            return a.text;
        });

        let paragraph = '';
        para.forEach((line) => {
            // eslint-disable-next-line no-plusplus
            paragraph += `${count++}. ${line} \n`;
        });
        setFormData((prevForm) => ({
            ...prevForm,
            content: {
                ...prevForm.content,
                text: {
                    de: formData?.content?.text?.de || paragraph,
                    en: formData?.content?.text?.en,
                },
                consent_text: {
                    de:
                        formData?.content?.consent_text?.de ||
                        welcomeTreatmentContract.consent_text_placeholder[0]?.text,
                    en: formData?.content?.consent_text?.en,
                },
            },
        }));
    };

    const availableServices = () => {
        if (!firstTimeLoad) {
            for (let j = 0; j < cachedUserData?.length; j++) {
                if (cachedUserData[j]?.prismic_profile?.data?.key[0]?.text === formData?.professional?.key) {
                    appointmentTypes.push(...(cachedUserData[j]?.dc_schedule_type_mappings ?? []));
                    break;
                }
            }
            const a = appointmentTypes
                .filter(
                    (mapping) =>
                        mapping.prismic_key &&
                        mapping.prismic_key !== '-' &&
                        prismicAppointmentTypesData[mapping.prismic_key]
                )
                .map((mapping) => ({
                    value: prismicAppointmentTypesData[mapping.prismic_key].name[0].text,
                    label: prismicAppointmentTypesData[mapping.prismic_key].name[0].text,
                    key: mapping.prismic_key,
                    id: mapping.appointment_type_id,
                }));

            if (edited) {
                const filteredInsuranceTypes = insuranceTypes?.filter(
                    (type) =>
                        formData.configuration?.insurance_types?.includes(type?.value) ||
                        formData.configuration?.insurance_types?.some(
                            (formInsurance) => formInsurance?.value === type?.value
                        )
                );
                // eslint-disable-next-line no-param-reassign
                formData.configuration.insurance_types = filteredInsuranceTypes;
            } else {
                const filteredInsuranceTypes = insuranceTypes.filter((insurance) =>
                    formData.configuration?.insurance_types?.some(
                        (formInsurance) => formInsurance.value === insurance.value
                    )
                );
                // eslint-disable-next-line no-param-reassign
                formData.configuration.insurance_types = filteredInsuranceTypes;
            }
            setServiceAppointments(a);
            consentText();
            setFirstTimeLoad(true);
        }
    };

    useEffect(() => {
        if (!welcomeTreatmentContract) return;

        setFormData((prevForm) => ({
            ...prevForm,
            content: {
                ...prevForm.content,
                name: formData?.professional?.label
                    ? `${formData?.professional?.label} - ${formData?.template_name}`
                    : edited && formData?.content?.name,
            },
        }));
        availableServices();
    }, [welcomeTreatmentContract]);

    const radioDropDown = [
        {
            label: 'Einmal pro Behandlung',
            value: 'Einmal pro Behandlung',
            id: 'radioDropDown1',
        },
        {
            label: 'Einmal pro Behandler:in',
            value: 'Einmal pro Behandler:in',
            id: 'radioDropDown2',
        },
    ];

    const onChange = (e, data) => {
        if (e.target.name === 'professional') {
            setFormData({
                ...formData,
                professional: {
                    key: data[0].key,
                    id: data[0].id,
                    label: data[0].label,
                },
            });
        } else if (e.target.name === 'template_key') {
            setFormData({
                ...formData,
                template_key: data[0].key,
                template_name: data[0].label,
            });
        } else if (e.target.name === 'name') {
            setFormData((prevForm) => ({
                ...prevForm,
                content: { ...prevForm.content, name: e.target.value },
            }));
        } else if (e.target.name === 'frequency_type') {
            setFormData((prevForm) => ({
                ...prevForm,
                configuration: { ...prevForm.configuration, frequency_type: e.target.value },
            }));
        } else if (e.target.name === 'appointment_types') {
            const index = formData.configuration.appointment_types?.findIndex((item) => item?.id === e.target.id);
            const serviceAppointment = serviceAppointments?.find((item) => item?.id === e.target.id);
            if (index === -1) {
                formData.configuration.appointment_types.push({
                    id: serviceAppointment?.id,
                    value: serviceAppointment?.value,
                    key: serviceAppointment?.key,
                });
            } else {
                formData.configuration.appointment_types.splice(index, 1);
            }
            setChange(!change);
        } else if (e.target.name === 'insurance_types') {
            const index = formData.configuration.insurance_types?.findIndex((item) => item.id === e.target.id);
            const obj = { value: e.target.value, label: e.target.value, id: e.target.id };
            if (index === -1) {
                formData.configuration.insurance_types.push(obj);
            } else {
                formData.configuration.insurance_types.splice(index, 1);
            }
            setChange(!change);
        } else if (e.target.name === 'text.de') {
            setFormData((prevForm) => ({
                ...prevForm,
                content: {
                    ...prevForm.content,
                    text: {
                        ...prevForm.content.text,
                        de: e.target.value,
                    },
                },
            }));
        } else if (e.target.name === 'text.en') {
            setFormData((prevForm) => ({
                ...prevForm,
                content: {
                    ...prevForm.content,
                    text: {
                        ...prevForm.content.text,
                        en: e.target.value,
                    },
                },
            }));
        } else if (e.target.name === 'consent_text') {
            setFormData((prevForm) => ({
                ...prevForm,
                content: {
                    ...prevForm.content,
                    consent_text: {
                        ...prevForm.content.consent_text,
                        de: e.target.value,
                    },
                },
            }));
        } else if (e.target.name === 'signature_type') {
            setFormData((prevForm) => ({
                ...prevForm,
                configuration: {
                    ...prevForm.configuration,
                    signature_type: e.target.checked,
                },
            }));
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleCheckAllChange = () => {
        setFormData((prevForm) => ({
            ...prevForm,
            configuration: {
                ...prevForm.configuration,
                appointment_types: checked ? [] : [...serviceAppointments],
            },
        }));
        setChecked(!checked);
    };

    const handleInsuranceCheckAllChange = () => {
        setFormData((prevForm) => ({
            ...prevForm,
            configuration: {
                ...prevForm.configuration,
                insurance_types: checkedInsurance ? [] : [...insuranceTypes],
            },
        }));
        setCheckedInsurance(!checkedInsurance);
    };

    return (
        <>
            <CCol className="pt-5 pb-3 custom-form-text" xs={12} sm={12} lg={8}>
                {t('Give the form a name')}
            </CCol>
            <CCol xs={12} sm={12} lg={12}>
                <FormInputText
                    name="name"
                    value={formData?.content?.name}
                    label={`${t('Document Name')}`}
                    onChange={(e) => onChange(e)}
                />
            </CCol>
            <CCol className="pt-3 pb-2 custom-form-text" xs={12} sm={12} lg={12}>
                {t('How often does this document have to be signed by each practitioner?')}
            </CCol>
            <CCol xs={12} sm={12} lg={12}>
                <FormRadioCheckList
                    name="frequency_type"
                    data={radioDropDown}
                    value={formData?.configuration?.frequency_type}
                    label={`${t('')}`}
                    onChange={(e) => onChange(e)}
                />
            </CCol>

            <CCol className="pt-3 pb-2 custom-form-text" xs={12} sm={12} lg={12}>
                Wähle die Versicherungsart aus, für die dieses Formular benötigt wird:
            </CCol>

            <CCol xs={12} sm={12} lg={12}>
                <CFormCheck
                    id="selectAllInsuranceTypes"
                    className="paragraph-text"
                    checked={insuranceTypes?.length === formData?.configuration?.insurance_types?.length}
                    label={t('Select All')}
                    onChange={handleInsuranceCheckAllChange}
                />
                <FormCheckboxList
                    name="insurance_types"
                    data={insuranceTypes}
                    value={formData?.configuration?.insurance_types}
                    onChange={(e) => onChange(e)}
                />
            </CCol>

            <CCol className="pt-3 pb-2 custom-form-text" xs={12} sm={12} lg={12}>
                {t('Select one or more appointment types that require this form:')}
            </CCol>
            <CCol xs={12} sm={12} lg={12}>
                <CFormCheck
                    id="selectAllAppointmentTypes"
                    checked={serviceAppointments?.length === formData?.configuration?.appointment_types?.length}
                    className="paragraph-text"
                    label={t('Select All')}
                    onChange={handleCheckAllChange}
                />
                <FormCheckboxList
                    name="appointment_types"
                    data={serviceAppointments}
                    checkedValue={checked}
                    value={formData?.configuration?.appointment_types}
                    onChange={(e) => onChange(e)}
                />
            </CCol>
            <CCol className="pt-3 pb-2 custom-form-text" xs={12} sm={12} lg={12}>
                {t('Enter the text provided by the practitioner, which the patient must read and sign')}
            </CCol>
            <CCol xs={12} sm={12} lg={12}>
                <FormTextArea
                    name="text.de"
                    rows={formData?.content?.text?.de?.length > 1000 ? 12 : 6}
                    value={formData?.content?.text?.de}
                    label="Deutschen Text hier eingeben"
                    onChange={(e) => onChange(e)}
                />
            </CCol>

            <CCol className="pt-2" xs={12} sm={12} lg={12}>
                <FormTextArea
                    name="text.en"
                    rows={formData?.content?.text?.en?.length > 1000 ? 12 : 6}
                    value={formData?.content?.text?.en}
                    label={`${t('Add English translation')}`}
                    onChange={(e) => onChange(e)}
                />
            </CCol>

            <CCol className="pt-5 pb-3 custom-form-text" xs={12} sm={12} lg={12}>
                {t('Edit the consent text if applicable')}
            </CCol>

            <CCol xs={12} sm={12} lg={12}>
                <FormTextArea
                    name="consent_text"
                    value={formData?.content?.consent_text?.de}
                    label={`${t('Consent Text')}`}
                    onChange={(e) => onChange(e)}
                />
            </CCol>

            <CCol className="pt-5 pb-3 custom-form-text" xs={12} sm={12} lg={8}>
                {t('Art der Unterschrift')}
            </CCol>
            <CCol className="pb-3 paragraph-text" xs={12} sm={12} lg={12}>
                {t(
                    'Standardmäßig müssen alle Formulare auf Papier unterschrieben werden, aber Sie können zulassen, dass sie auch digital unterschrieben werden.'
                )}
            </CCol>
            <CCol xs={12} sm={12} lg={12}>
                <FormSwitch
                    name="signature_type"
                    checked={formData?.configuration?.signature_type}
                    value={formData?.configuration?.signature_type}
                    label={`${t('Digitale Signatur zulassen')}`}
                    onChange={(e) => onChange(e)}
                />
            </CCol>
        </>
    );
};

export default TreatmentContractShort;
