import { CContainer } from '@coreui/react';

import { FilledFormsState } from '@utils/constants';

const StatusIcon = ({ state }) => {
    switch (state) {
        case FilledFormsState.ALL:
            return (
                <CContainer
                    className="bg-primary text-center text-white"
                    style={{ borderRadius: '0.3125rem', padding: '0.25rem 0' }}
                >
                    Status
                </CContainer>
            );
        case FilledFormsState.SOME:
            return (
                <CContainer
                    className="bg-secondary text-center"
                    style={{ borderRadius: '0.3125rem', padding: '0.25rem 0' }}
                >
                    Status
                </CContainer>
            );
        case FilledFormsState.NONE:
        default:
            return (
                <CContainer
                    className="bg-danger text-center text-white"
                    style={{ borderRadius: '0.3125rem', padding: '0.25rem 0' }}
                >
                    Status
                </CContainer>
            );
    }
};

export default StatusIcon;
