const pathStyle = {
    fill: 'rgba(255, 255, 255, 0.6)',
};

const HeraklesIcon = () => {
    return (
        <svg
            className="nav-icon"
            style={{ width: '64px', height: '20px', marginLeft: '-1rem' }}
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="152.000000pt"
            height="160.000000pt"
            viewBox="0 0 152.000000 160.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path
                    style={pathStyle}
                    d="M114 1576 c-12 -30 1 -59 26 -63 82 -11 90 -27 90 -184 l0 -99 125 0
125 0 0 116 c0 114 1 117 27 140 14 12 41 25 60 29 30 5 33 9 33 40 l0 35
-240 0 c-199 0 -242 -2 -246 -14z"
                />
                <path
                    style={pathStyle}
                    d="M773 1583 c-7 -2 -13 -17 -13 -33 0 -24 5 -29 44 -39 25 -6 51 -18
59 -26 18 -17 32 -143 24 -211 l-5 -44 123 0 123 0 4 114 c3 103 5 117 26 139
14 14 40 27 63 31 38 6 40 8 37 39 l-3 32 -235 2 c-129 1 -241 -1 -247 -4z"
                />
                <path
                    style={pathStyle}
                    d="M30 1034 l0 -125 32 3 c31 3 34 6 42 51 4 26 12 47 17 47 5 0 9 5 9
10 0 6 12 23 27 38 l27 27 353 3 353 3 0 -453 c0 -250 -4 -468 -9 -486 -13
-44 -30 -59 -82 -69 -38 -7 -44 -12 -44 -33 l0 -25 255 0 255 0 3 26 c3 23 -1
26 -50 35 -91 16 -88 -1 -88 542 l0 464 102 -4 c70 -2 103 -7 106 -15 2 -7 10
-13 18 -13 8 0 14 -7 14 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 4 -20
9 -20 5 0 12 -18 16 -40 6 -38 9 -40 41 -40 l34 0 0 125 0 125 -730 0 -730 0
0 -126z"
                />
                <path
                    style={pathStyle}
                    d="M235 1028 c-2 -7 -6 -75 -7 -150 -3 -154 -6 -160 -80 -173 -35 -6
-38 -9 -38 -41 l0 -34 245 0 245 0 0 34 c0 31 -3 34 -35 40 -19 4 -46 17 -60
30 -24 23 -25 27 -25 165 l0 141 -120 0 c-88 0 -122 -3 -125 -12z"
                />
            </g>
        </svg>
    );
};

export default HeraklesIcon;
