import { useTranslation } from 'react-i18next';

import { useTheme } from '@context/theme/ThemeContext';
import { useLocationStore } from '../../zustandStore';

export const AppointmentStatus = {
    PENDING: 'pending',
    ARRIVED: 'arrived',
    CHECKED_IN: 'checked_in',
    MANUALLY_CHECKED_IN: 'manually_checked_in',
    CANCELLED: 'cancelled',
};

const AppointmentStatusTag = ({ status, checkinTime = '', ticketNumber = '' }) => {
    const { t } = useTranslation();
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const { colors } = useTheme();

    let color;
    let textColor = 'black';
    switch (status) {
        case AppointmentStatus.CHECKED_IN:
        case AppointmentStatus.MANUALLY_CHECKED_IN:
            color = colors.secondary;
            textColor = 'white';
            break;
        case AppointmentStatus.PENDING:
        default:
            color = '#F5F5F5';
            break;
    }

    return (
        <div className="d-flex align-items-center justify-content-end">
            <div
                style={{
                    background: color,
                    fontSize: '0.8rem',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.25rem 0.5rem',
                    borderRadius: '0.3rem',
                    border: `0.5px solid ${colors.secondary}`,
                    color: textColor,
                    minWidth: status === AppointmentStatus.MANUALLY_CHECKED_IN ? '14rem' : 'auto',
                }}
            >{`${t(status)}${
                checkinTime && status !== AppointmentStatus.PENDING
                    ? ` - ${new Date(checkinTime).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                      })}`
                    : ''
            }${
                ticketNumber &&
                status !== AppointmentStatus.PENDING &&
                selectedLocation?.config.is_patient_call_system_active
                    ? ` #${ticketNumber}`
                    : ''
            }`}</div>
        </div>
    );
};

export default AppointmentStatusTag;
