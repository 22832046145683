import { useContext, useEffect, useMemo, useState } from 'react';
import { CButton, CCard, CCol, CContainer, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import useSWRImmutable from 'swr/immutable';

import AWSContext from '@context/AWSContext';
import Progress from '../../components/progress/Progress';
import SelectTemplate from './SelectTemplate';
import TreatmentContractShort from './TreatmentContractShort';
import PracUserAvailabilityContext from '@context/pracuseravailability/PracUserAvailabilityContext';
import PreviewAndSave from './PreviewAndSave';
import { usePrismicStore, useProfessionalsStore } from '../../zustandStore';
import { loadCachedUserData } from '@utils/helpers';
import LoadingPage from '../pages/loading/LoadingPage';

const AddForms = () => {
    const { t } = useTranslation(['common']);
    const [edited, setEdited] = useState(false);
    const [directFromDoctor, setDirectFromDoctor] = useState(false);
    const [isNextEnabled, setIsNextEnabled] = useState(false);
    const [change, setChange] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const awsContext = useContext(AWSContext);
    const { userToken } = awsContext;
    const pracUserAvailabilityContext = useContext(PracUserAvailabilityContext);
    const [page, setPage] = useState(0);
    const { createFormData, error, formSuccessMessage, clearError } = pracUserAvailabilityContext;
    const cachedUserData = useProfessionalsStore((state) => state.cachedUserData);
    const professionals = usePrismicStore((state) => state.professionals);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);
    const consentFormDocuments = usePrismicStore((state) => state.consentFormDocuments);

    useSWRImmutable(
        !cachedUserData || currentCustomer?.customer_id?.[0]?.text
            ? ['professionals', currentCustomer?.customer_id?.[0]?.text]
            : null,
        async () => loadCachedUserData(userToken)
    );

    const [formData, setFormData] = useState({
        professional_id: '', // HASH
        id: '', // RANGE -> UUID (generated)
        templates_image: [],
        status: '', // ENUM: ACTIVE | INACTIVE | ARCHIVED (GLOBAL_SECONDARY_INDEX)
        template_key: '',
        professional: { key: '', id: '' },

        content: {
            name: '',
            text: { de: '', en: '' },
            consent_text: { de: '', en: '' }, // OPTIONAL
        },
        configuration: {
            insurance_types: [],
            frequency_type: '', // ENUM: ONCE_PER_APPOINTMENT | ONCE_PER_PROFESSIONAL
            appointment_types: [],
            signature_type: false, // ENUM: DIGITAL | PAPER | UNKNOWN
        },
    });

    const FormTitles = [`${t('Choose a template')}`, `${t('Configuration')}`, `${t('Preview & Save')}`];

    const mapPrismicKeysToScheduleTypes = () => {
        for (let i = 0; i < cachedUserData?.length; i++) {
            const prismicKeyMap = cachedUserData[i]?.dc_schedule_type_mappings?.reduce((map, item) => {
                // eslint-disable-next-line no-param-reassign
                map[item.dc_schedule_type_id] = item.prismic_key;
                return map;
            }, {});
            cachedUserData[i]?.dc_schedule_types?.forEach((item) => {
                if (prismicKeyMap[item.id]) {
                    // eslint-disable-next-line no-param-reassign
                    item.prismic_key = prismicKeyMap[item.id];
                }
            });
        }
    };

    const convertObjectAndSaveAppointment = (value) => {
        if (formData?.id === '') {
            for (let i = 0; i < cachedUserData?.length; i++) {
                if (cachedUserData[i]?.dc_user_mapping?.prismic_key === formData?.professional?.key) {
                    formData.professional_id = cachedUserData[i]?.dc_user_mapping?.user_id; // it should be dynamic
                    formData.professional.id = cachedUserData[i]?.dc_user_mapping?.user_id; // it should be dynamic
                }
            }
            formData.id = uuidv4();

            formData.status = 'active';
        }
        formData.configuration.signature_type = value?.configuration?.signature_type === false ? 'paper' : 'digital';
        formData.configuration.insurance_types = formData?.configuration?.insurance_types?.map((item) => {
            return item.value;
        });
        delete formData.templates_image;
        delete formData.template_name;
        delete formData.professional?.label;
        createFormData(userToken, formData);
    };

    const setToDefaultAndGoBack = () => {
        setFormData({
            professional_id: '', // HASH
            id: '', // RANGE -> UUID (generated)
            templates_image: [],
            status: '', // ENUM: ACTIVE | INACTIVE | ARCHIVED (GLOBAL_SECONDARY_INDEX)
            template_key: '',
            professional: { key: '', id: '' },

            content: {
                name: '',
                text: { de: '', en: '' },
                consent_text: { de: '', en: '' }, // OPTIONAL
            },

            configuration: {
                insurance_types: [],
                frequency_type: '', // ENUM: ONCE_PER_APPOINTMENT | ONCE_PER_PROFESSIONAL
                appointment_types: [],
                signature_type: false, // ENUM: DIGITAL | PAPER | UNKNOWN
            },
        });
        setPage(0);
        setIsNextEnabled(false);
        if (edited) {
            history.push('/professionals');
        }
    };
    useEffect(() => {
        if (!cachedUserData) return;
        mapPrismicKeysToScheduleTypes();

        if (formData?.professional?.key && formData?.template_key && page === 0) {
            setIsNextEnabled(true);
        }
        if (
            formData?.configuration?.frequency_type &&
            formData?.configuration?.appointment_types?.length > 0 &&
            formData?.configuration?.insurance_types?.length > 0 &&
            formData?.content?.name &&
            formData?.content?.consent_text?.de &&
            formData?.content?.text?.de &&
            page === 1
        ) {
            setIsNextEnabled(true);
        }
        if (location.checkData === 'onlyDoctor' && !directFromDoctor) {
            const {
                dc_user_mapping: { prismic_key = '', user_id = '' },
                data: { display_name = '' },
            } = location.state;
            setDirectFromDoctor(true);
            setFormData((prev) => ({
                ...prev,
                professional: {
                    key: prismic_key,
                    id: user_id,
                    label: display_name?.[0]?.text,
                },
            }));
        } else if (location.state && !edited && !location.checkData) {
            const {
                id,
                status,
                template_key,
                professional: { id: professionalId, key: professionalKey },
                content: { name, text, consent_text },
                configuration: { frequency_type, appointment_types, insurance_types, signature_type },
            } = location.state;
            setEdited(true);
            setFormData({
                professional_id: professionalId, // HASH
                id: id, // RANGE -> UUID (generated)
                templates_image: [],
                status: status, // ENUM: ACTIVE | INACTIVE | ARCHIVED (GLOBAL_SECONDARY_INDEX)
                template_key: template_key,
                professional: { key: professionalKey, id: professionalId },
                content: {
                    name: name,
                    text: { de: text?.de, en: text?.en },
                    consent_text: { de: consent_text?.de, en: consent_text?.en }, // OPTIONAL
                },
                configuration: {
                    insurance_types: insurance_types,
                    frequency_type: frequency_type, // ENUM: ONCE_PER_APPOINTMENT | ONCE_PER_PROFESSIONAL
                    appointment_types: appointment_types,
                    signature_type: signature_type === 'digital', // ENUM: DIGITAL | PAPER | UNKNOWN
                },
            });
        }
    }, [page, formData, change, cachedUserData]);

    useEffect(() => {
        if (error) {
            toast.error(error, { duration: 3000, position: 'top-right' });
            clearError();
        }
    }, [error]);

    useEffect(() => {
        if (formSuccessMessage) {
            setFormData({
                professional_id: '', // HASH
                id: '', // RANGE -> UUID (generated)
                templates_image: [],
                status: '', // ENUM: ACTIVE | INACTIVE | ARCHIVED (GLOBAL_SECONDARY_INDEX)
                template_key: '',
                professional: { key: '', id: '' },

                content: {
                    name: '',
                    text: { de: '', en: '' },
                    consent_text: { de: '', en: '' }, // OPTIONAL
                },

                configuration: {
                    frequency_type: '', // ENUM: ONCE_PER_APPOINTMENT | ONCE_PER_PROFESSIONAL
                    appointment_types: [],
                    insurance_types: [],
                    signature_type: false, // ENUM: DIGITAL | PAPER | UNKNOWN
                },
            });
            setPage(0);
            toast.success(edited ? 'Form Updated Successfully' : formSuccessMessage, {
                duration: 3000,
                position: 'top-right',
            });
            if (edited) {
                history.push('/professionals');
            }
            setIsNextEnabled(false);
            clearError();
        }
    }, [formSuccessMessage]);

    const PageDisplay = useMemo(() => {
        if (page === 0) {
            return <SelectTemplate edited={edited} formData={formData} setFormData={setFormData} />;
        }
        if (page === 1) {
            if (formData.template_key === 'treatment-contract-short') {
                return (
                    <TreatmentContractShort
                        change={change}
                        setChange={setChange}
                        edited={edited}
                        formData={formData}
                        setFormData={setFormData}
                    />
                );
            }
            return (
                <TreatmentContractShort
                    change={change}
                    setChange={setChange}
                    edited={edited}
                    formData={formData}
                    setFormData={setFormData}
                />
            );
        }
        if (page === 2) {
            return <PreviewAndSave formData={formData} />;
        }
        return null;
    }, [page, edited, formData, change]);

    const ctaText = useMemo(() => {
        if (page !== 2) return t('Further');
        if (edited) return t('Update Form');
        return t('Save Form');
    }, [page, edited]);

    if (!cachedUserData || !consentFormDocuments || !professionals) return <LoadingPage />;

    return (
        <div>
            <CContainer>
                <CCol xs={6} sm={6} lg={6} className="add-form-text pb-3">
                    Formular hinzufügen
                </CCol>
                <CCard className="p-5">
                    <Progress step={page} title={FormTitles[page]} />
                    {PageDisplay}
                    <CCol lg={12} className="pt-5">
                        <CRow>
                            <CCol xs="auto" className="me-auto">
                                {!(page === 0 && !edited) && (
                                    <CCol className="paragraph-text" onClick={setToDefaultAndGoBack}>
                                        <span className="custom-pointer">{t('Interrupt')}</span>
                                    </CCol>
                                )}
                            </CCol>
                            <CCol xs="auto">
                                <div
                                    className="mb-0"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'right',
                                    }}
                                >
                                    {page !== 0 ? (
                                        <CButton
                                            className="back-button paragraph-text"
                                            variant="outline"
                                            color="warning"
                                            onClick={() => {
                                                setPage((currPage) => currPage - 1);
                                            }}
                                        >
                                            {t('Return')}
                                        </CButton>
                                    ) : (
                                        ''
                                    )}
                                    <CButton
                                        className="paragraph-text"
                                        color="secondary"
                                        style={{ marginLeft: 5 }}
                                        onClick={() => {
                                            if (page === FormTitles.length - 1) {
                                                convertObjectAndSaveAppointment(formData);
                                            } else {
                                                if (page === 0) setIsNextEnabled(false);
                                                setPage((currPage) => currPage + 1);
                                            }
                                        }}
                                        disabled={!isNextEnabled}
                                    >
                                        {ctaText}
                                    </CButton>
                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                </CCard>
            </CContainer>
        </div>
    );
};

export default AddForms;
