// eslint-disable-next-line max-classes-per-file
export class Dot {
    element;

    track;

    trackTotalLength;

    tZero;

    hidden = false;

    delay = 0;

    previousOpacity = -1;

    positions = [];

    color;

    constructor(
        svgElement,
        pathElement,
        positions,
        color,
        radiusMultiplier = 0,
        delay = 0,
        size = 8,
        dynamicDotSize = true
    ) {
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', '0');
        circle.setAttribute('cy', '0');
        circle.setAttribute('r', String(dynamicDotSize ? size * 0.99 ** radiusMultiplier : size));
        // circle.style.opacity = 0.98 * Math.pow(0.98, radiusMultiplier);
        circle.setAttribute('class', 'dot');
        circle.setAttribute('fill', color);
        circle.style.willChange = 'transform';
        svgElement?.appendChild(circle);
        this.element = circle;
        this.track = pathElement;
        this.trackTotalLength = pathElement?.getTotalLength() || 0;
        this.delay = delay;
        this.positions = positions;
        this.hide();
    }

    reset = () => {
        this.tZero = Date.now() + this.delay;
    };

    hide = () => {
        this.hidden = true;
        this.element.style.display = 'none';
    };

    show = () => {
        this.hidden = false;
        this.element.style.display = 'flex';
    };

    updateOpacity = (value) => {
        let opacity;
        if (value <= 0.1 && value * 5 < 1) opacity = value * 5;
        else if (value >= 0.9 && (1 - value) * 5 < 1) opacity = (1 - value) * 5;
        else opacity = 1;

        if (Math.abs(opacity - this.previousOpacity) > 0.01) {
            this.element.style.opacity = String(opacity);
            this.previousOpacity = opacity;
        }
    };

    move = (u) => {
        if (Date.now() > this.tZero) {
            if (this.hidden) this.show();
            this.updateOpacity(u);
            const index = Math.floor(u * (this.positions.length - 1));
            const p = this.positions[index];
            this.element.setAttribute('transform', `translate(${p.x}, ${p.y})`);
        }
    };

    clear = () => {
        document.querySelector('.svg')?.removeChild(this.element);
    };
}

export class Animation {
    dots = [];

    duration = 0;

    stopped = false;

    amount = 0;

    delay = 0;

    dotSize = 0;

    dynamicDotSize = true;

    positions = [];

    color;

    constructor(positions, color, amount = 10, duration = 2000, delay = 10, dotSize = 8, dynamicDotSize = true) {
        this.positions = positions;
        this.create(amount, duration, delay, dotSize, dynamicDotSize, color);
    }

    create = (amount, duration, delay, dotSize, dynamicDotSize, color) => {
        const dots = [];
        const svgElement = document.querySelector('.svg');
        const pathElement = document.querySelector('.path');
        for (let i = 0; i < amount; i++)
            dots.push(new Dot(svgElement, pathElement, this.positions, color, i, i * delay, dotSize, dynamicDotSize));
        this.dots = dots;
        this.duration = duration;
        this.amount = amount;
        this.delay = delay;
        this.dotSize = dotSize;
        this.dynamicDotSize = dynamicDotSize;
    };

    animate = () => {
        if (this.stopped) return;
        let allDotsHidden = true;
        this.dots.forEach((dot) => {
            const u = Math.min((Date.now() - dot.tZero) / this.duration, 1);

            dot.move(u);
            if (u === 1) {
                if (!dot.hidden) dot.hide();
            } else allDotsHidden = false;
        });

        if (allDotsHidden) this.resetDots();

        requestAnimationFrame(this.animate);
    };

    resetDots = () => {
        this.dots.forEach((dot) => {
            dot.reset();
        });
    };

    start = () => {
        this.resetDots();
        this.stopped = false;
        this.animate();
    };

    removeDots = () => {
        this.dots.forEach((dot) => dot.clear());
    };

    getValues = () => {
        return {
            duration: this.duration,
            amount: this.amount,
            delay: this.delay,
        };
    };

    clear = () => {
        this.stopped = true;
        this.removeDots();
    };
}
