import { useMemo, useReducer } from 'react';

import {
    getPatientById,
    getPatients,
    getUserMappingById,
    getUserMappings,
    postUserMappingById,
} from '../../api/dcpatient/DcPatientCalls';
import DcPatientReducer from './DcPatientReducer';
import DcPatientContext from './DcPatientContext';
import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_DC_PATIENTS_FAIL,
    GET_ALL_DC_PATIENTS_SUCCESS,
    GET_DC_PATIENT_BY_ID_FAIL,
    GET_DC_PATIENT_BY_ID_SUCCESS,
    GET_DC_PATIENT_MAPPINGS_FAIL,
    GET_DC_PATIENT_MAPPINGS_SUCCESS,
    GET_DC_PATIENTS_IDS_FAIL,
    GET_DC_PATIENTS_IDS_SUCCESS,
    POST_PATIENT_DATA_FAIL,
    POST_PATIENT_DATA_SUCCESS,
    SET_TO_DEFAULT,
} from '../../store/actions';

const DcPatientState = ({ children }) => {
    const initialState = {
        dcPatients: [],
        dcPatientsIds: null,
        dcPatientMappings: [],
        singleDcPatientRecord: null,
        error: null,
        successMessage: null,
    };
    const [state, dispatch] = useReducer(DcPatientReducer, initialState);
    // getPatientsCall
    const getDcPatients = async (userToken) => {
        try {
            const response = await getPatients(userToken);
            dispatch({
                type: GET_ALL_DC_PATIENTS_SUCCESS,
                payload: response.data.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_DC_PATIENTS_FAIL,
                payload: error.message,
            });
        }
    };

    // getDcPatientMappings
    const getDcPatientMappings = async (userToken) => {
        try {
            const response = await getUserMappings(userToken);
            dispatch({
                type: GET_DC_PATIENT_MAPPINGS_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: GET_DC_PATIENT_MAPPINGS_FAIL,
                payload: error.message,
            });
        }
    };

    // getDcPatientById
    const getDcPatientById = async (userToken, id, instanceId) => {
        try {
            const response = await getPatientById(userToken, id, instanceId);
            console.debug(response);
            dispatch({
                type: GET_DC_PATIENT_BY_ID_SUCCESS,
                payload: response.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_DC_PATIENT_BY_ID_FAIL,
                payload: error.message,
            });
        }
    };

    // getPatientsCall
    const getDcPatientMappingByIds = async (userToken, id) => {
        try {
            const response = await getUserMappingById(userToken, id);
            dispatch({
                type: GET_DC_PATIENTS_IDS_SUCCESS,
                payload: response.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_DC_PATIENTS_IDS_FAIL,
                payload: error.message,
            });
        }
    };

    // sendPatientMappingData
    const sendPatientMappingData = async (userToken, id, mappingDataInfo) => {
        try {
            const response = await postUserMappingById(userToken, id, mappingDataInfo);
            dispatch({
                type: POST_PATIENT_DATA_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: POST_PATIENT_DATA_FAIL,
                payload: error.message,
            });
        }
    };

    const clearRecords = () => {
        dispatch({ type: CLEAR_OBJECTS });
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            dcPatients: state.dcPatients,
            dcPatientsIds: state.dcPatientsIds,
            dcPatientMappings: state.dcPatientMappings,
            singleDcPatientRecord: state.singleDcPatientRecord,
            successMessage: state.successMessage,
            error: state.error,
            getDcPatients: getDcPatients,
            getDcPatientMappingByIds: getDcPatientMappingByIds,
            getDcPatientById: getDcPatientById,
            sendPatientMappingData: sendPatientMappingData,
            getDcPatientMappings: getDcPatientMappings,
            clearRecords: clearRecords,
            clearError: clearError,
            setToDefault: setToDefault,
        }),
        [state]
    );

    return <DcPatientContext.Provider value={value}>{children}</DcPatientContext.Provider>;
};
export default DcPatientState;
