import {
    CButton,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';

import { useTodaysAppointmentsStore } from '../../zustandStore';

const LegacyCheckinModal = ({ showLegacyCheckinModal, startCheckin, appointment }) => {
    const { t } = useTranslation();
    const appointmentTicketNumber = useTodaysAppointmentsStore((state) => state.appointmentTicketNumber);

    return (
        <CModal
            scrollable
            visible={showLegacyCheckinModal}
            onClose={() => {
                useTodaysAppointmentsStore.setState({ showLegacyCheckinModal: false });
            }}
        >
            <CModalHeader />
            <CModalBody lg={12} md={12} sm={12} xs={12}>
                <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                    <h3>{t('Check-In')}</h3>
                    <CRow xs={{ gutterY: 0 }}>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CCardTitle style={{ fontSize: 14, paddingBottom: 10 }}>
                                {' '}
                                {t('Assign the visitor a unique code to check-in')}{' '}
                            </CCardTitle>
                            <CForm>
                                <div className="mb-3">
                                    <CFormInput
                                        type="text"
                                        value={appointmentTicketNumber[appointment?.id]}
                                        placeholder={`${t('Call number')}`}
                                        name="number"
                                        onChange={(e) =>
                                            useTodaysAppointmentsStore.setState({
                                                appointmentTicketNumber: {
                                                    ...appointmentTicketNumber,
                                                    [appointment?.id]: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>

            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => {
                        useTodaysAppointmentsStore.setState({ showLegacyCheckinModal: false });
                    }}
                >
                    {t('Close')}
                </CButton>
                <CButton
                    onClick={() => {
                        startCheckin();
                        useTodaysAppointmentsStore.setState({ showLegacyCheckinModal: false });
                    }}
                    color="secondary"
                >
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default LegacyCheckinModal;
