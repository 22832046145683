import { CCol, CContainer, CForm, CFormInput, CRow } from '@coreui/react';

export const SideList = ({ src, name, designation }) => {
    return (
        <CContainer className="p-3 custom-border">
            <CRow>
                <CCol xs={2}>
                    <img className="imgIcon" src={src} alt={name} />
                </CCol>
                <CCol xs={10}>
                    <div className="list-name-type">{name}</div>
                    <div className="small text-medium-emphasis">
                        <span>{designation}</span>
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    );
};

export const SearchBar = ({ setSearchBy, placeholder }) => {
    return (
        <CCol sm={12} xs={12} className="">
            <CForm>
                <div className="">
                    <CFormInput
                        type="text"
                        id="search"
                        placeholder={placeholder}
                        onChange={(e) => setSearchBy(e.target.value)}
                    />
                </div>
            </CForm>
        </CCol>
    );
};
