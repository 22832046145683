import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            // translation file path
            loadPath: '/assets/i18n/common/{{lng}}.json',
        },
        fallbackLng: 'de',
        lng: 'de',
        // disabled in production
        debug: false,
        // can have multiple namespaces, in case you want to divide a huge
        // translation into smaller pieces and load them on demand
        ns: ['common'],

        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
