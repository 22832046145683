import { useContext, useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cilPencil, cilTrash } from '@coreui/icons';

import ProfessionalContext from '@context/professional/ProfessionalContext';
import AWSContext from '@context/AWSContext';
import { dayjs } from '@utils/dayjsSetup.js';
import { usePrismicStore } from '../../zustandStore';
import { getDomain, updateForm } from '@utils/helpers';
import { FormTemplateStatus } from '@utils/constants';

const FrequencyType = {
    'once-per-appointment': 'Einmal pro Behandlung',
    'once-per-professional': 'Einmal pro Behandler:in',
};

const RightSideBar = ({ data, templates }) => {
    const { t } = useTranslation(['common']);
    const professionalContext = useContext(ProfessionalContext);
    const awsContext = useContext(AWSContext);
    const history = useHistory();
    const { userToken } = awsContext;
    const { formsTemplates, getFormsTemplatesById } = professionalContext;
    const [loading, setLoading] = useState(false);
    const appointmentTypes = usePrismicStore((state) => state.appointmentTypes);
    const { currentCustomer } = usePrismicStore.getState();

    const getFormTemplates = async () => {
        setLoading(true);
        await getFormsTemplatesById(userToken, data?.dc_user_mapping?.user_id, false);
        setLoading(false);
    };

    useEffect(() => {
        if (!data?.dc_user_mapping?.user_id) return;
        getFormTemplates();
        console.debug('getFormsTemplatesByIddd', data, formsTemplates);
    }, [data, currentCustomer]);

    const deactivateForm = async (formTemplate) => {
        // eslint-disable-next-line no-param-reassign
        formTemplate.status = FormTemplateStatus.ARCHIVED;
        await updateForm(userToken, formTemplate);
        getFormsTemplatesById(userToken, data?.dc_user_mapping?.user_id, false);
    };

    const getLink = (item) => {
        let link = '';
        const linkItem = templates?.find((element) => element?.key === item.template_key);

        if (linkItem?.key === 'treatment-contract-extended') {
            link += linkItem?.data?.preview_image_2_optional?.url ?? '';
        } else {
            link += linkItem?.data?.preview_image?.url ?? '';
        }

        return link;
    };

    const redirectPage = (templateData) => {
        let url;
        const domain = getDomain(currentCustomer);
        if (!templateData.is_custom) {
            url = `https://${
                import.meta.env.VITE_ENVIRONMENT === 'local' || import.meta.env.VITE_ENVIRONMENT === 'dev' ? 'dev.' : ''
            }patients.${domain}/${
                templateData?.template_key === 'treatment-contract-short'
                    ? 'consent-treatment-welcome'
                    : 'extended-treatment-welcome'
            }?professional_id=${templateData?.professional_id}&template_id=${templateData?.id}`;
        } else {
            url = `https://${
                import.meta.env.VITE_ENVIRONMENT === 'local' || import.meta.env.VITE_ENVIRONMENT === 'dev' ? 'dev.' : ''
            }patients.${domain}/custom-anamnesis?professional_id=${templateData?.professional_id}&template_id=${
                templateData?.id
            }&preview=true`;
        }

        window.open(url, 'SingleSecondaryWindow', 'noreferrer');
    };

    const editTemplate = (templateData) => {
        history.push({ pathname: '/forms-portal', state: templateData });
    };

    const shiftToAddFormPage = (templateData) => {
        history.push({ pathname: '/forms-portal', state: templateData, checkData: 'onlyDoctor' });
    };

    return (
        <div className="p-4">
            <CCol sm={12} lg={12} className="name">
                {data?.data?.display_name[0]?.text}
            </CCol>
            <CCol sm={12} lg={12} className="name-intro pb-3">
                {data?.data?.type?.slug}
            </CCol>
            {/* eslint-disable-next-line no-nested-ternary */}
            {formsTemplates?.length === 0 || !appointmentTypes ? (
                loading || !appointmentTypes ? (
                    <CCol lg={12} md={12} sm={12} xs={12}>
                        <CCard>
                            <CCardBody>
                                <div className="text-center">
                                    <div className="spinner-grow text-info" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                ) : (
                    <CCol sm={12} lg={12}>
                        <CCard className="p-3 custom-background">
                            <CCol className="name-intro-list">Diese Behandler:in hat kein verknüpftes Formular.</CCol>
                            <CCol sm="auto" xs={6} className="pt-3">
                                <CButton color="secondary" onClick={() => shiftToAddFormPage(data)}>
                                    Ein neues Formular hinzufügen
                                </CButton>
                            </CCol>
                        </CCard>
                    </CCol>
                )
            ) : (
                formsTemplates
                    ?.filter((item) => item.status !== FormTemplateStatus.ARCHIVED)
                    .map((item) => (
                        <CRow key={item?.id}>
                            <CCol xs>
                                <CCard className="mb-4">
                                    <CRow>
                                        <CCol xs={12} md={4} lg={4} xl={4} style={{ objectFit: 'contain' }}>
                                            {!item.is_custom ? (
                                                <img
                                                    alt="Form Preview"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    src={getLink(item)}
                                                />
                                            ) : (
                                                <p className="p-0 m-0">Custom</p>
                                            )}
                                        </CCol>

                                        <CCol
                                            xs={12}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            className="p-4"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <CCol>
                                                <CCol className="name">{item?.content?.name ?? item.form_name_de}</CCol>
                                                <CCol className="name-intro">
                                                    {FrequencyType[item?.configuration?.frequency_type] ??
                                                        item?.configuration?.frequency_type}
                                                </CCol>
                                                <CCol className="pb-2 terminarten-text">Versicherungsart</CCol>
                                                {item?.configuration?.insurance_types?.map((insuranceType) => (
                                                    <CCol key={insuranceType} className="list-name-type">
                                                        <li>{t(insuranceType)}</li>
                                                    </CCol>
                                                ))}
                                                <CCol className="pb-2 terminarten-text">Terminarten</CCol>
                                                {data?.dc_schedule_types?.length ===
                                                    item?.configuration?.appointment_types?.length ||
                                                (item?.is_custom &&
                                                    item?.configuration?.appointment_types?.length === 0) ? (
                                                    <CCol className="list-name-type">
                                                        <li>Alle Termine</li>
                                                    </CCol>
                                                ) : (
                                                    item?.configuration?.appointment_types?.map((appointmentType) => (
                                                        <CCol key={appointmentType?.id} className="list-name-type">
                                                            <li>
                                                                {appointmentTypes?.[appointmentType.key]?.name[0]
                                                                    .text ?? '-'}
                                                            </li>
                                                        </CCol>
                                                    ))
                                                )}
                                                <CCol sm="auto" xs={6} className="pt-3">
                                                    <CButton
                                                        color="secondary"
                                                        className="custom-btn-text"
                                                        onClick={() => redirectPage(item)}
                                                    >
                                                        Vorschau
                                                    </CButton>
                                                </CCol>
                                            </CCol>
                                            <CCol className="pt-3">
                                                <CRow>
                                                    <CCol md="auto">
                                                        <div className="name-intro-date">{`Zuletzt aktualisiert ${dayjs(
                                                            item?.meta?.updated_at
                                                        ).format('DD.MM.YYYY')}`}</div>
                                                    </CCol>
                                                    <CCol md="auto">
                                                        <CCol
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                if (!item.is_custom) {
                                                                    editTemplate(item);
                                                                } else {
                                                                    history.push(
                                                                        `/anamnesis-builder?template_id=${item.id}&professional_id=${item.professional.id}`
                                                                    );
                                                                }
                                                            }}
                                                            xs="auto"
                                                        >
                                                            <CIcon icon={cilPencil} size="sm" />
                                                            {' Formular bearbeiten'}
                                                        </CCol>
                                                    </CCol>
                                                    <CCol md="auto">
                                                        <CCol
                                                            onClick={() => deactivateForm(item)}
                                                            xs="auto"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <CIcon icon={cilTrash} size="sm" />
                                                            {' Löschen'}
                                                        </CCol>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        </CCol>
                                    </CRow>
                                </CCard>
                            </CCol>
                        </CRow>
                    ))
            )}
        </div>
    );
};

export default RightSideBar;
