import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CCard, CCardBody, CCardTitle, CCol, CForm, CFormInput, CFormSelect } from '@coreui/react';

import PatientContext from '@context/patient/PatientContext';

const PatientHealthInfoModal = ({ func }) => {
    const { t } = useTranslation(['common']);
    const patientContext = useContext(PatientContext);
    const { patientHealthInsuranceDetails, successMessage, error } = patientContext;

    const [patientHealthInsuranceInfo, setPatientHealthInsuranceInfo] = useState({
        insurance_id: '',
        user_id: '',
        insurance_company: '',
        insurance_type: '',
        insurance_company_id: '',
        insurance_valid_until: '',
        insurance_status: '',
    });
    func(patientHealthInsuranceInfo);
    const patientHealthOnChange = (e) => {
        setPatientHealthInsuranceInfo({
            ...patientHealthInsuranceInfo,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (patientHealthInsuranceDetails !== null) {
            setPatientHealthInsuranceInfo(patientHealthInsuranceDetails);
        }
    }, [patientHealthInsuranceDetails, successMessage, error]);

    return patientHealthInsuranceInfo !== null && !(JSON.stringify(patientHealthInsuranceInfo) === '{}') ? (
        <>
            <CCol lg={12} md={12} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Health Insurance Data')} </CCardTitle>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Insurance Id')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="number"
                                    value={patientHealthInsuranceInfo.insurance_id}
                                    name="insurance_id"
                                    onChange={patientHealthOnChange}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Insurance Company Id')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="number"
                                    value={patientHealthInsuranceInfo.insurance_company_id}
                                    name="insurance_company_id"
                                    onChange={patientHealthOnChange}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Insurance Company Name')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="text"
                                    value={patientHealthInsuranceInfo.insurance_company}
                                    name="insurance_company"
                                    onChange={patientHealthOnChange}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Insurance Valid Until')} </CCardTitle>
                        <CForm>
                            <div className="mb-3" />
                        </CForm>
                        <div data-coreui-locale="en-US" data-coreui-toggle="date-picker" id="datePicker1" />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Insurance Status')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Member')}`, value: `${t('Member')}` },
                                { label: `${t('Family Member')}`, value: `${t('Family Member')}` },
                                { label: `${t('Pensioner')}`, value: `${t('Pensioner')}` },
                            ]}
                            name="insurance_status"
                            value={patientHealthInsuranceInfo.insurance_status}
                            onChange={patientHealthOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Insurance Type')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Public')}`, value: `${t('Public')}` },
                                { label: `${t('Private')}`, value: `${t('Private')}` },
                            ]}
                            name="insurance_type"
                            value={patientHealthInsuranceInfo.insurance_type}
                            onChange={patientHealthOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        </>
    ) : (
        ''
    );
};

export default PatientHealthInfoModal;
