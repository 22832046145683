import axios from 'axios';

const BASE_URL = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_ADMIN_API_PATH
}/`;

export async function getById(userToken, id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
        },
    };
    try {
        const res = await axios.get(`${BASE_URL}user/health-profiles/${id}`, config);
        console.debug(res);
        return res;
    } catch (error) {
        console.debug(error);
        return error;
    }
}
