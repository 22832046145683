import {
    CButton,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormSelect,
    CFormSwitch,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AWSContext from '@context/AWSContext';
import { getCalendars } from '../../api/dccalendar/DcCalendarCalls';
import { postUserMappingById } from '../../api/dcprofessional/DcProfessionalCalls';
import { useLocationStore, usePractitionersMappingStore, usePrismicStore } from '../../zustandStore';

const AddMappingModal = ({ showPractitionerName, reloadMappings }) => {
    const { t } = useTranslation();
    const [selectedDCLocation, setSelectedDCLocation] = useState(undefined);
    const [selectedDCPro, setSelectedDCPro] = useState('');
    const [selectedPrismicPro, setSelectedPrismicPro] = useState('');
    const [dcCalendarsData, setDCCalendarsData] = useState([]);
    const [selectedCalendars, setSelectedCalendars] = useState([]);
    const [userId, setUserId] = useState('');
    const [subLocKeys, setSubLocKeys] = useState([]);

    const dcProfessionals = usePractitionersMappingStore((state) => state.professionals);
    const calendars = usePractitionersMappingStore((state) => state.calendars);
    const prismicProfessionals = usePrismicStore((state) => state.professionals);
    const locationHubs = usePrismicStore((state) => state.locationHubs);
    const instanceConfigs = usePrismicStore((state) => state.instanceConfigs);
    const mapping = usePractitionersMappingStore((state) => state.mapping);
    const showAddMappingModal = usePractitionersMappingStore((state) => state.showAddMappingModal);
    const { userToken } = useContext(AWSContext);

    const addMapping = async (dcUser, prismicUser, calData, id = uuidv4()) => {
        const data = {
            dc_user_id: dcUser,
            user_id: id,
            dc_instance_id: dcProfessionals.filter((el) => el.user_id === dcUser)[0].instance_id,
            prismic_key: prismicUser,
            dc_calendars_data: calData,
        };
        try {
            await postUserMappingById(userToken, id, data);
            reloadMappings();
        } catch (e) {
            console.error(e);
        }
    };

    const getSubLocKeysFromSecondaryLocations = () => {
        const keys = new Set();
        const { secondaryLocations } = useLocationStore.getState();

        Object.values(secondaryLocations).forEach((subLoc) => {
            subLoc.forEach((el) => {
                keys.add(el?.key[0].text);
            });
        });

        return [...keys];
    };

    const setStartingState = () => {
        setSelectedDCLocation(prismicProfessionals[mapping.prismic_key]?.location_hub.id);
        setSelectedPrismicPro(mapping.prismic_key);
        setSelectedDCPro(mapping.dc_user_id);
        setDCCalendarsData(mapping.dc_calendars_data);
        setUserId(mapping.user_id);
        setSelectedCalendars(calendars?.filter((val) => val.employee_id === selectedDCPro));
        setSubLocKeys(getSubLocKeysFromSecondaryLocations());
    };

    useEffect(() => {
        if (showAddMappingModal && mapping) {
            setStartingState();
        }
    }, [showAddMappingModal, mapping]);

    // Preloads calendars when DC Professional is selected
    useEffect(() => {
        if (selectedDCPro) {
            setSelectedCalendars(calendars?.filter((val) => val.employee_id === selectedDCPro));
            setDCCalendarsData(mapping.dc_calendars_data);
        }
    }, [selectedDCPro]);

    // Sync when there is a mismatch between DCCalendarsData and selectedCalendars
    const syncSelectedCalendarsWithDCCalendarsData = () => {
        const diff = (selectedCalendars?.length ?? 0) - (dcCalendarsData?.length ?? 0);
        if (diff > 0) {
            const dcCalendarsDataIds = dcCalendarsData.map((val) => val.dc_calendar_id);
            const filteredCalendars = selectedCalendars.filter((val) => !dcCalendarsDataIds.includes(val.id));
            const arr = [...dcCalendarsData];
            filteredCalendars.forEach((val) => {
                arr.push({
                    dc_calendar_id: val.id,
                    sub_loc_key: '-',
                    is_active: false,
                    dc_instance_id: val.instance_id,
                });
            });
            setDCCalendarsData(arr);
        } else {
            const selectedCalendarsIds = selectedCalendars?.map((val) => val.id);
            setDCCalendarsData(dcCalendarsData?.filter((val) => selectedCalendarsIds.includes(val.dc_calendar_id)));
        }
    };
    useEffect(() => {
        if (dcCalendarsData?.length !== selectedCalendars?.length) {
            syncSelectedCalendarsWithDCCalendarsData();
        }
    }, [dcCalendarsData, selectedCalendars]);

    const loadCalendars = async () => {
        const response = await getCalendars(userToken);
        if (!response.data?.data) {
            return;
        }
        usePractitionersMappingStore.setState({ calendars: response.data.data });
    };

    useEffect(() => {
        if (!calendars) loadCalendars();
    }, [calendars]);

    const invertActive = (id) => {
        const calendarsData = [...dcCalendarsData];
        const idx = calendarsData.findIndex((el) => el.dc_calendar_id === id);
        calendarsData[idx].is_active = !calendarsData[idx]?.is_active;
        setDCCalendarsData(calendarsData);
    };

    const setSubLoc = (subLoc, id) => {
        const calendarsData = [...dcCalendarsData];
        const idx = calendarsData.findIndex((el) => el.dc_calendar_id === id);
        calendarsData[idx].sub_loc_key = subLoc;
        setDCCalendarsData(calendarsData);
    };

    const filterOutCalendar = (id) => {
        setSelectedCalendars(selectedCalendars.filter((el) => el.id !== id));
        setDCCalendarsData(dcCalendarsData.filter((el) => el.dc_calendar_id !== id));
    };

    const resetCalendars = () => {
        if (selectedDCPro) {
            setSelectedCalendars(calendars?.filter((val) => val.employee_id === selectedDCPro));
            setDCCalendarsData(mapping.dc_calendars_data);
        }
    };

    return (
        <CModal
            scrollable
            visible={showAddMappingModal}
            onClose={() => usePractitionersMappingStore.setState({ showAddMappingModal: false })}
        >
            <CModalHeader />
            <CModalBody>
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    <h3>{userId ? t('Edit Mapping') : t('Add Mapping')}</h3>
                    <CRow xs={{ gutterY: 0 }}>
                        {prismicProfessionals && (
                            <CCol>
                                <CForm>
                                    <div className="mb-3">
                                        <CCardTitle style={{ fontSize: 14 }}>Location</CCardTitle>
                                        <CFormSelect
                                            options={[
                                                { label: 'Choose Location', value: undefined },
                                                ...locationHubs.map((hub) => {
                                                    return {
                                                        label: hub.data.title[0].text,
                                                        value: hub.id,
                                                    };
                                                }),
                                            ]}
                                            value={selectedDCLocation}
                                            onChange={(e) => setSelectedDCLocation(e.target.value)}
                                            name="DCLocationSelect"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <CCardTitle style={{ fontSize: 14 }}>Professional in DocCirrus</CCardTitle>
                                        <CFormSelect
                                            disabled={!selectedDCLocation}
                                            options={[
                                                { label: 'Choose DC professional', value: '-' },
                                                ...(dcProfessionals
                                                    ?.sort((a, b) => {
                                                        return a.name.last_name.localeCompare(b.name.last_name);
                                                    })
                                                    .filter(
                                                        (val) =>
                                                            val?.instance_id ===
                                                                instanceConfigs[selectedDCLocation]?.instance_id[0]
                                                                    .text && val.account.type === 'PHYSICIAN'
                                                    )
                                                    ?.map((val) => {
                                                        return {
                                                            label: showPractitionerName(val.user_id),
                                                            value: val.user_id,
                                                        };
                                                    }) || []),
                                            ]}
                                            value={selectedDCPro}
                                            onChange={(e) => setSelectedDCPro(e.target.value)}
                                            name="DCProfessionalSelect"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <CCardTitle style={{ fontSize: 14 }}>Professional in Prismic</CCardTitle>
                                        <CFormSelect
                                            options={[
                                                { label: 'Choose professional', value: '-' },
                                                ...Object.keys(prismicProfessionals).map((professional) => {
                                                    return {
                                                        label: prismicProfessionals[professional].key[0].text,
                                                        value: prismicProfessionals[professional].key[0].text,
                                                    };
                                                }),
                                            ]}
                                            value={selectedPrismicPro}
                                            onChange={(e) => setSelectedPrismicPro(e.target.value)}
                                            name="professionalSelect"
                                        />
                                    </div>
                                    <CButton
                                        onClick={() => {
                                            resetCalendars();
                                        }}
                                        color="primary"
                                    >
                                        {t('Add all calendars')}
                                    </CButton>
                                    {selectedCalendars
                                        ?.filter((val) => val.employee_id === selectedDCPro)
                                        .map((val) => (
                                            <div key={val.id}>
                                                <li>{val.name}</li>
                                                {dcCalendarsData.length > 1 && (
                                                    <CFormSelect
                                                        options={[
                                                            { label: 'Choose Sub Loc Key', value: '-' },
                                                            ...subLocKeys.map((el) => {
                                                                return { label: el, value: el };
                                                            }),
                                                        ]}
                                                        value={
                                                            dcCalendarsData.find((el) => el.dc_calendar_id === val.id)
                                                                ?.sub_loc_key
                                                        }
                                                        onChange={(e) => setSubLoc(e.target.value, val.id)}
                                                        name="subLocSelect"
                                                    />
                                                )}
                                                <CFormSwitch
                                                    key={val.dc_calendar_id}
                                                    label={t('Active calendar')}
                                                    checked={
                                                        dcCalendarsData?.find((el) => el.dc_calendar_id === val.id)
                                                            ?.is_active
                                                    }
                                                    onChange={() => invertActive(val.id)}
                                                />
                                                <CButton
                                                    onClick={() => {
                                                        syncSelectedCalendarsWithDCCalendarsData();
                                                        filterOutCalendar(val.id);
                                                    }}
                                                    color="secondary"
                                                >
                                                    {t('Remove')}
                                                </CButton>
                                            </div>
                                        ))}
                                </CForm>
                            </CCol>
                        )}
                    </CRow>
                </CContainer>
            </CModalBody>

            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => {
                        usePractitionersMappingStore.setState({ showAddMappingModal: false });
                    }}
                >
                    {t('Close')}
                </CButton>
                <CButton
                    disabled={
                        !selectedPrismicPro || selectedPrismicPro === '-' || !selectedDCPro || selectedDCPro === '-'
                    }
                    onClick={() => {
                        addMapping(selectedDCPro, selectedPrismicPro, dcCalendarsData, userId);
                        usePractitionersMappingStore.setState({
                            showAddMappingModal: false,
                        });
                    }}
                    color="secondary"
                >
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default AddMappingModal;
