import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import { useTranslation } from 'react-i18next';

import { routes } from '../routes';
import { useLocationStore } from '../zustandStore';
import { dayjs } from '@utils/dayjsSetup.js';

const AppBreadcrumb = () => {
    const currentLocation = useLocation().pathname;
    const { t } = useTranslation(['common']);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);

    const getRouteName = (pathname, routesArray) => {
        return routesArray.find((route) => route.path === pathname)?.name;
    };

    const getBreadcrumbs = (location) => {
        const breadcrumbs = [];
        location.split('/').reduce((prev, curr, index, array) => {
            const currentPathname = `${prev}/${curr}`;
            breadcrumbs.push({
                pathname: currentPathname,
                name: getRouteName(currentPathname, routes),
                active: index + 1 === array.length,
            });
            return currentPathname;
        });
        return breadcrumbs;
    };

    const breadcrumbs = getBreadcrumbs(currentLocation);

    if (!breadcrumbs[0].name) return null;

    return (
        <CBreadcrumb className="m-0 ms-2">
            <CBreadcrumbItem href="/">{t('Home')}</CBreadcrumbItem>
            {breadcrumbs.map((breadcrumb) => {
                return (
                    <CBreadcrumbItem
                        {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
                        key={breadcrumb.pathname}
                    >
                        {selectedLocation &&
                            `${breadcrumb.name} ${
                                currentLocation !== '/today-appointments'
                                    ? ''
                                    : ` - ${selectedLocation.name} ${dayjs().format('DD MMM YYYY')}`
                            }`}
                    </CBreadcrumbItem>
                );
            })}
        </CBreadcrumb>
    );
};

export default memo(AppBreadcrumb);
