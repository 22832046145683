import { useEffect, useState } from 'react';
import { CBadge } from '@coreui/react';

import { badgeStyle } from '../../styles';

export const HeraklesFormLinkStatus = {
    COMPLETED: 'completed',
    PENDING: 'pending',
    INCOMPLETE: 'incomplete',
};

const HeraklesFormLink = ({ status, name, link }) => {
    const [badgeColor, setBadgeColor] = useState(null);

    useEffect(() => {
        switch (status) {
            case HeraklesFormLinkStatus.COMPLETED:
                setBadgeColor('primary');
                break;
            case HeraklesFormLinkStatus.INCOMPLETE:
                setBadgeColor('danger');
                break;
            case HeraklesFormLinkStatus.PENDING:
                setBadgeColor('dark-light');
                break;
            default:
                setBadgeColor(null);
                break;
        }
    }, [status]);

    if (!badgeColor) return null;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
            }}
        >
            <CBadge style={badgeStyle} color={badgeColor} shape="rounded-circle">
                {' '}
            </CBadge>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
                style={{
                    textDecorationLine: link ? 'underline' : 'none',
                    cursor: link ? 'pointer' : 'not-allowed',
                    opacity: link ? 1 : '0.75',
                }}
                className="m-0 p-0"
                onClick={() => link && window.open(link, '_blank')}
            >
                {name}
            </p>
        </div>
    );
};

export default HeraklesFormLink;
