import { useEffect, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { cilPencil } from '@coreui/icons';
import { CButton, CFormSwitch } from '@coreui/react';

import { useAdminConfigStore, useLocationStore, useTodaysAppointmentsStore } from '../../zustandStore';
import { FeatureKey } from '@utils/helpers';

const FormsSelection = ({
    appointment,
    showFormSelection,
    setShowFormSelection,
    saveSelection,
    isAutoSaveEnabled = false,
}) => {
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const selectedForms = useTodaysAppointmentsStore((state) => state.selectedForms);
    const professionalFormTemplates = useTodaysAppointmentsStore((state) => state.professionalFormTemplates);
    const isAnamnesisSelectedForm = useTodaysAppointmentsStore((state) => state.isAnamnesisSelectedForm);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const [selectedFormsObj, setSelectedFormsObj] = useState({});

    useEffect(() => {
        setSelectedFormsObj(
            selectedForms[appointment.id]?.reduce((obj, form) => {
                // eslint-disable-next-line no-param-reassign
                obj[form.id] = true;
                return obj;
            }, {}) ?? {}
        );
    }, [selectedForms]);

    const resetSelectedFormsObj = () => {
        setSelectedFormsObj(
            selectedForms[appointment.id]?.reduce((obj, form) => {
                // eslint-disable-next-line no-param-reassign
                obj[form.id] = true;
                return obj;
            }, {}) ?? {}
        );
    };

    if (!showFormSelection) {
        return (
            <div className="my-3">
                {(selectedForms[appointment.id]?.length > 0 || isAnamnesisSelectedForm[appointment.id]) && (
                    <div className="d-flex flex-column gap-2 mb-2">
                        {isAnamnesisSelectedForm[appointment.id] && <p className="m-0 p-0">- Anamnese</p>}
                        {selectedForms[appointment.id]?.map((form) => {
                            const formTemplate = professionalFormTemplates[appointment.professional.key][form.id];
                            return (
                                <p className="m-0 p-0" key={form.id}>
                                    {`- ${
                                        formTemplate?.is_custom
                                            ? formTemplate?.form_name_de
                                            : formTemplate?.content.name
                                    }`}
                                </p>
                            );
                        })}
                    </div>
                )}
                <div
                    onClick={() => setShowFormSelection(true)}
                    className="d-flex align-items-center gap-2"
                    style={{ cursor: 'pointer' }}
                >
                    <CIcon icon={cilPencil} />
                    Formulare hinzufügen
                </div>
            </div>
        );
    }

    return (
        <div className="my-3">
            <div className="d-flex flex-column gap-2">
                {(!featureAccess[FeatureKey.CUSTOM_ANAMNESIS] ||
                    !selectedLocation.config.is_custom_anamnesis_activated ||
                    !Object.values(professionalFormTemplates[appointment.professional.key]).some(
                        (template) => template.is_custom
                    )) && (
                    <div className="d-flex align-items-center gap-2">
                        <CFormSwitch
                            style={{ cursor: 'pointer' }}
                            className="m-0"
                            onChange={() =>
                                useTodaysAppointmentsStore.setState({
                                    isAnamnesisSelectedForm: {
                                        ...isAnamnesisSelectedForm,
                                        [appointment.id]: !isAnamnesisSelectedForm[appointment.id],
                                    },
                                })
                            }
                            checked={isAnamnesisSelectedForm[appointment.id]}
                        />
                        <p className="m-0 p-0">Anamnese</p>
                    </div>
                )}
                {Object.values(professionalFormTemplates[appointment.professional.key])
                    .filter((form) =>
                        featureAccess[FeatureKey.CUSTOM_ANAMNESIS] &&
                        selectedLocation.config.is_custom_anamnesis_activated
                            ? form
                            : !form.is_custom
                    )
                    .map((form) => (
                        <div key={form.id} className="d-flex align-items-center gap-2">
                            <CFormSwitch
                                onChange={() => {
                                    setSelectedFormsObj({
                                        ...selectedFormsObj,
                                        [form.id]: !selectedFormsObj[form.id],
                                    });
                                    if (!isAutoSaveEnabled) return;
                                    saveSelection({
                                        ...selectedFormsObj,
                                        [form.id]: !selectedFormsObj[form.id],
                                    });
                                }}
                                style={{ cursor: 'pointer' }}
                                className="m-0"
                                checked={selectedFormsObj[form.id]}
                            />
                            <p className="m-0 p-0">
                                {form.is_custom ? `${form.form_name_de} [Anamnese]` : form.content.name}
                            </p>
                        </div>
                    ))}
            </div>
            {!isAutoSaveEnabled && (
                <div className="d-flex align-items-center gap-3 mt-3">
                    <CButton onClick={() => saveSelection(selectedFormsObj)} color="secondary">
                        Speichern
                    </CButton>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <p
                        onClick={() => {
                            setShowFormSelection(false);
                            resetSelectedFormsObj();
                        }}
                        style={{ cursor: 'pointer' }}
                        className="link-dark m-0 p-0"
                    >
                        Abbrechen
                    </p>
                </div>
            )}
        </div>
    );
};

export default FormsSelection;
