import { CAccordionBody, CAccordionHeader, CAccordionItem, CButton, CFormInput } from '@coreui/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { usePrismicStore } from '../../zustandStore';
import Question from './Question';

export const Category = ({
    categoryData,
    setShowQuestionSelection,
    selectedCategory,
    setSelectedCategory,
    removeCategory,
    removeQuestion,
    updateCategoryName,
    setCategories,
    categories,
    isFixed = false,
}) => {
    const questions = usePrismicStore((state) => state.questions);

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const newQuestions = [...categoryData.questions];

        newQuestions.splice(source.index, 1);
        newQuestions.splice(
            destination.index,
            0,
            categoryData.questions.find((question) => question.key === draggableId)
        );

        setCategories(
            categories.map((categoryEntry) =>
                categoryEntry.id === selectedCategory
                    ? {
                          ...categoryEntry,
                          questions: newQuestions,
                      }
                    : categoryEntry
            )
        );
    };

    return (
        <CAccordionItem>
            <CAccordionHeader
                onClick={() =>
                    selectedCategory !== categoryData.id
                        ? setSelectedCategory(categoryData.id)
                        : setSelectedCategory(null)
                }
            >
                <p className="p-0 m-0" style={{ opacity: !isFixed ? 1 : 0.4 }}>
                    {categoryData.germanName || '-'}
                </p>
            </CAccordionHeader>
            <CAccordionBody className="d-flex flex-column gap-4">
                {isFixed && (
                    <div style={{ borderRadius: '8px', background: '#F5F5F5', padding: '20px' }}>
                        <p className="p-0 m-0">
                            Diese Kategorie ist für alle Formulare obligatorisch und kann nicht bearbeitet werden.
                        </p>
                    </div>
                )}
                <section className="d-flex flex-column gap-2">
                    <h5>Kategorie Name</h5>
                    <div className="d-flex gap-3">
                        <CFormInput
                            style={{ width: '15rem' }}
                            disabled={isFixed}
                            placeholder="Kategoriename in Deutsch"
                            value={categoryData.germanName}
                            onChange={(e) => updateCategoryName(e.target.value, 'germanName')}
                        />
                        <CFormInput
                            style={{ width: '15rem' }}
                            disabled={isFixed}
                            placeholder="Kategoriename in Englisch"
                            value={categoryData.englishName}
                            onChange={(e) => updateCategoryName(e.target.value, 'englishName')}
                        />
                    </div>
                </section>
                <section className="d-flex flex-column gap-2">
                    <h5>Fragen</h5>
                    {categoryData.questions.length > 0 && (
                        <div
                            style={{ borderTop: '2px solid #F5F5F5', borderBottom: '2px solid #F5F5F5' }}
                            className="my-2"
                        >
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId={String(categoryData.id ?? '')}>
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {categoryData.questions.map((question, index) => (
                                                <Draggable key={question.key} draggableId={question.key} index={index}>
                                                    {(draggableProvided) => (
                                                        <div
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                            ref={draggableProvided.innerRef}
                                                        >
                                                            <Question
                                                                style={{
                                                                    borderTop: index !== 0 ? '2px solid #F5F5F5' : '',
                                                                }}
                                                                required={question.is_required}
                                                                question={!isFixed ? questions[question.key] : question}
                                                                removeQuestion={() =>
                                                                    removeQuestion(question.key, categoryData.id)
                                                                }
                                                                isFixed={isFixed}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <CButton onClick={() => setShowQuestionSelection(true)} color="secondary" disabled={isFixed}>
                            Frage hinzufügen
                        </CButton>
                        <CButton
                            onClick={() => removeCategory()}
                            color="danger"
                            style={{ color: 'white' }}
                            disabled={isFixed}
                        >
                            Kategorie löschen
                        </CButton>
                    </div>
                </section>
            </CAccordionBody>
        </CAccordionItem>
    );
};
