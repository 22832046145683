export const cardTitleStyle = {
    fontSize: 13,
    position: 'absolute',
    top: '-10px',
    background: 'white',
    padding: '0 0.2rem',
};

export const cardStyle = {
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
};

export const cardContentStyle = {
    fontSize: 16,
    margin: 0,
    wordBreak: 'break-all',
};

export const badgeStyle = {
    marginTop: 0,
    width: '1rem',
    height: '1rem',
};

export const buttonBarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
};

export const buttonsContainer = {
    display: 'flex',
    gap: '15px',
};

export const headContainerStyle = {
    display: 'flex',
    alignItems: 'center',
};

export const logoStyle = {
    height: '1rem',
    objectFit: 'contain',
};

export const patientTabTitleStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
};

export const stickySearchStyle = {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    position: 'sticky',
    top: '5rem',
    backgroundColor: 'rgba(249, 248, 244, 0.6)',
    backdropFilter: 'blur(0.3rem)',
    zIndex: 5,
};
