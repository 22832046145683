import {
    CButton,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import FormsSelection from './FormsSelection';
import { useAdminConfigStore, useLocationStore, usePrismicStore, useTodaysAppointmentsStore } from '../../zustandStore';
import { FeatureKey } from '@utils/helpers';
import ProfessionalSelection from '../ProfessionalSelection';

const GlobalManualCheckinModal = ({
    manualCheckinPatientName,
    setManualCheckinPatientName,
    showGlobalManualCheckinModal,
    globalManualCheckin,
}) => {
    const { t } = useTranslation();
    const [selectedProfessional, setSelectedProfessional] = useState('');
    const [showFormSelection, setShowFormSelection] = useState(false);
    const [appointmentId, setAppointmentId] = useState(uuidv4());
    const professionals = usePrismicStore((state) => state.professionals);
    const usePcsForGlobalManualCheckin = useTodaysAppointmentsStore((state) => state.usePcsForGlobalManualCheckin);
    const appointmentTicketNumber = useTodaysAppointmentsStore((state) => state.appointmentTicketNumber);
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);

    useEffect(() => {
        if (showGlobalManualCheckinModal) {
            setSelectedProfessional('');
            setAppointmentId(uuidv4());
            useTodaysAppointmentsStore.setState({
                appointmentTicketNumber: { ...appointmentTicketNumber, global: '' },
            });
            setManualCheckinPatientName('');
        }
    }, [showGlobalManualCheckinModal]);

    const saveSelection = async (selectedFormsObj) => {
        const { professionalFormTemplates, selectedForms } = useTodaysAppointmentsStore.getState();

        const newSelectedForms = Object.keys(selectedFormsObj)
            .map(
                (formId) =>
                    selectedFormsObj[formId] && {
                        id: formId,
                        key: professionalFormTemplates[selectedProfessional][formId].template_key,
                    }
            )
            .filter(Boolean);

        useTodaysAppointmentsStore.setState({
            selectedForms: { ...selectedForms, [appointmentId]: newSelectedForms },
        });
    };

    return (
        <CModal
            scrollable
            visible={showGlobalManualCheckinModal}
            onClose={() => useTodaysAppointmentsStore.setState({ showGlobalManualCheckinModal: false })}
        >
            <CModalHeader />
            <CModalBody lg={12} md={12} sm={12} xs={12}>
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    <h3>{t('Manual Checkin')}</h3>
                    <CRow xs={{ gutterY: 0 }}>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CCardTitle style={{ fontSize: 14 }}> {t('Please enter the patient`s name')} </CCardTitle>
                        </CCol>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CForm>
                                <div className="mb-3">
                                    <CFormInput
                                        type="text"
                                        value={manualCheckinPatientName}
                                        placeholder={`${t('Patient name')}`}
                                        name="name"
                                        onChange={(e) => setManualCheckinPatientName(e.target.value)}
                                    />
                                </div>
                            </CForm>
                        </CCol>
                        {!usePcsForGlobalManualCheckin && selectedLocation?.config.is_patient_call_system_active && (
                            <>
                                <CCol lg={12} md={12} sm={12} xs={12}>
                                    <CCardTitle style={{ fontSize: 14 }}>
                                        {' '}
                                        {t('Assign the visitor a unique code to check-in')}{' '}
                                    </CCardTitle>
                                </CCol>
                                <CCol lg={12} md={12} sm={12} xs={12}>
                                    <CForm>
                                        <div className="mb-3">
                                            <CFormInput
                                                type="text"
                                                value={appointmentTicketNumber.global}
                                                placeholder={`${t('Call number')}`}
                                                name="number"
                                                onChange={(e) =>
                                                    useTodaysAppointmentsStore.setState({
                                                        appointmentTicketNumber: {
                                                            ...appointmentTicketNumber,
                                                            global: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </CForm>
                                </CCol>
                            </>
                        )}
                        {professionals && (
                            <CCol lg={12} md={12} sm={12} xs={12}>
                                <CForm>
                                    <div className="mb-3">
                                        <CCardTitle style={{ fontSize: 14 }}>Professional</CCardTitle>
                                        <ProfessionalSelection
                                            selectedProfessional={selectedProfessional}
                                            setSelectedProfessional={setSelectedProfessional}
                                        />
                                    </div>
                                </CForm>
                                <CCol lg={12} md={12} sm={12} xs={12} style={{ minHeight: '2rem' }}>
                                    {selectedProfessional && professionals[selectedProfessional] && (
                                        <p>{`Zugewiesenes Zimmer: ${professionals[selectedProfessional].rooms?.[0]?.text}`}</p>
                                    )}
                                </CCol>
                                <CCol lg={12} md={12} sm={12} xs={12} style={{ minHeight: '2rem' }}>
                                    {featureAccess[FeatureKey.CONSENT_FORMS] &&
                                        selectedProfessional &&
                                        professionals[selectedProfessional] && (
                                            <FormsSelection
                                                appointment={{
                                                    id: appointmentId,
                                                    professional: { key: selectedProfessional },
                                                }}
                                                showFormSelection={showFormSelection}
                                                setShowFormSelection={setShowFormSelection}
                                                saveSelection={saveSelection}
                                                isAutoSaveEnabled
                                            />
                                        )}
                                </CCol>
                            </CCol>
                        )}
                    </CRow>
                </CContainer>
            </CModalBody>

            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => useTodaysAppointmentsStore.setState({ showGlobalManualCheckinModal: false })}
                >
                    {t('Close')}
                </CButton>
                <CButton
                    disabled={
                        !selectedProfessional ||
                        selectedProfessional === '-' ||
                        (!usePcsForGlobalManualCheckin &&
                            selectedLocation?.config.is_patient_call_system_active &&
                            !appointmentTicketNumber.global) ||
                        !manualCheckinPatientName
                    }
                    onClick={() => {
                        globalManualCheckin(selectedProfessional, appointmentId);
                        useTodaysAppointmentsStore.setState({
                            showGlobalManualCheckinModal: false,
                        });
                    }}
                    color="secondary"
                >
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default GlobalManualCheckinModal;
