import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import StatusIcon from '../StatusIcon';
import rightArrowIcon from '@assets/right_arrow.svg';

const PatientEntry = ({ item, selectedPatient, updateSelectedPatient, detailsLoading }) => {
    return (
        <TableBody
            key={item.id}
            style={{
                cursor: !detailsLoading ? 'pointer' : 'not-allowed',
                userSelect: 'none',
                background: selectedPatient?.user_id === item.user_id ? '#F9F8F4' : 'transparent',
            }}
        >
            <TableRow
                sx={{ '& > *': { borderBottom: 'unset', paddingBottom: 'inherit' } }}
                onClick={() => updateSelectedPatient(item)}
            >
                <TableCell>{item.personal_data.data.personal.first_name}</TableCell>
                <TableCell>{item.personal_data.data.personal.last_name}</TableCell>
                <TableCell>{item.personal_data.data.personal.date_of_birth}</TableCell>
                <TableCell>
                    <StatusIcon state={item.filledForms} />
                </TableCell>
                <TableCell>
                    <img src={rightArrowIcon} alt="" />
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

export default PatientEntry;
