import { useEffect, useState } from 'react';
import { CButton, CFormInput, CFormSwitch, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import { useTranslation } from 'react-i18next';

import { usePrismicStore } from '../../zustandStore';
import QuestionOption from './QuestionOption';

const QuestionSelectionModal = ({ showQuestionSelection, setShowQuestionSelection, addQuestion }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [selectedQuestions, setSelectedQuestions] = useState({});
    const [isRequired, setIsRequired] = useState(false);
    const [filteredQuestions, setFilteredQuestions] = useState({});

    useEffect(() => {
        const { questions } = usePrismicStore.getState();
        if (!search) {
            setFilteredQuestions(questions);
            return;
        }

        const filteredQuestionEntries = Object.entries(questions).filter(
            (question) =>
                question[1].title[0].text.toLowerCase().includes(search.toLowerCase()) ||
                question[1].question_tags[0]?.text.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredQuestions(Object.fromEntries(filteredQuestionEntries));
    }, [search]);

    useEffect(() => {
        if (selectedQuestions.length !== 1) return;
        setIsRequired(filteredQuestions[selectedQuestions[0]].is_required);
    }, [selectedQuestions]);

    useEffect(() => {
        if (!showQuestionSelection) return;
        setSearch('');
        setSelectedQuestions({});
    }, [showQuestionSelection]);

    return (
        <CModal scrollable visible={showQuestionSelection} onClose={() => setShowQuestionSelection(false)}>
            <CModalHeader>Frage hinzufügen</CModalHeader>
            <CModalBody className="d-flex flex-column gap-2">
                <CFormInput
                    placeholder="Nach einer Frage suchen"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="d-flex flex-column gap-3 my-2 py-2" style={{ height: '90vh', overflow: 'scroll' }}>
                    {Object.keys(filteredQuestions).map((questionKey) => (
                        <QuestionOption
                            key={questionKey}
                            question={filteredQuestions[questionKey]}
                            selectedQuestions={selectedQuestions}
                            setSelectedQuestions={setSelectedQuestions}
                        />
                    ))}
                </div>
                <div className="d-flex align-items-center">
                    <CFormSwitch
                        color="secondary"
                        checked={isRequired}
                        onChange={() => setIsRequired(!isRequired)}
                        style={{ cursor: 'pointer' }}
                    />
                    <p className="m-0 p-0">Pflichtfrage</p>
                </div>
            </CModalBody>

            <CModalFooter>
                <CButton color="light" onClick={() => setShowQuestionSelection(false)}>
                    {t('Cancel')}
                </CButton>
                <CButton onClick={() => addQuestion(selectedQuestions, isRequired)} color="secondary">
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default QuestionSelectionModal;
