import axios from 'axios';

import { getCustomerId } from '@utils/helpers';

const BASE_URL = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PRACTITIONER_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_DC_API_PATH
}/`;
const API_VERSION = 'v2';

export async function postScheduleMapping(userToken, mapping) {
    const config = {
        headers: {
            Authorization: userToken,
            version: API_VERSION,
            customer_id: getCustomerId(),
        },
    };

    try {
        return await axios.post(`${BASE_URL}schedule-types/${mapping.dc_schedule_type_id}/mappings`, mapping, config);
    } catch (error) {
        return error;
    }
}
