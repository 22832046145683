import { useContext, useEffect } from 'react';
import { CContainer, CRow } from '@coreui/react';
import useSWRImmutable from 'swr/immutable';

import AWSContext from '@context/AWSContext';
import PractitionersOnboarding from '../practitioners-onboarding/PractitionersOnboarding';
import { useLocationStore, usePrismicStore, useProfessionalsStore } from '../../zustandStore';
import { loadCachedUserData } from '@utils/helpers';

const Professionals = () => {
    const awsContext = useContext(AWSContext);
    const { userToken } = awsContext;
    const consentFormDocuments = usePrismicStore((state) => state.consentFormDocuments);
    const professionalsPrismicData = usePrismicStore((state) => state.professionals);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const cachedUserData = useProfessionalsStore((state) => state.cachedUserData);

    useSWRImmutable(
        !cachedUserData || currentCustomer?.customer_id?.[0]?.text
            ? ['professionals', currentCustomer?.customer_id?.[0]?.text]
            : null,
        async () => loadCachedUserData(userToken)
    );

    const getProfessionalsData = () => {
        let professionalsArray = [...cachedUserData].filter((userData) => userData.dc_user_mapping?.user_id);

        const templates = consentFormDocuments
            .filter((document) => document.tags.includes('doctor'))
            .map((a) => {
                return {
                    data: a.data,
                    lan: a.data,
                    key: a.data.key[0].text,
                    label: a.data.template_name[0].text,
                    value: a.data.key[0].text,
                };
            });

        professionalsArray = professionalsArray
            .map((userData) => ({
                ...userData,
                ...professionalsPrismicData[userData.prismic_profile?.data?.key[0]?.text],
                ...userData.prismic_profile,
            }))
            .filter(
                (item) =>
                    item.prismic_customer_config.data.customer_id[0].text === currentCustomer.customer_id[0].text &&
                    item.dc_user.instance_id === selectedLocation.instanceId
            );

        useProfessionalsStore.setState({
            templateArray: templates,
            finalRecords: professionalsArray,
            childData: professionalsArray[0],
        });
    };

    useEffect(() => {
        if (!cachedUserData || !consentFormDocuments || !professionalsPrismicData || !currentCustomer) {
            return;
        }
        getProfessionalsData(cachedUserData);
    }, [cachedUserData, professionalsPrismicData, consentFormDocuments, selectedLocation]);

    return (
        <CContainer className="pt-5">
            <CRow xs={{ gutterY: 2 }}>
                <PractitionersOnboarding />
            </CRow>
        </CContainer>
    );
};

export default Professionals;
