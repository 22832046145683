import { useContext, useEffect, useState } from 'react';
import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CCard,
    CCardBody,
    CCardText,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormSelect,
    CPagination,
    CPaginationItem,
    CRow,
} from '@coreui/react';

import PatientContext from '@context/patient/PatientContext';
import AlertContext from '@context/alert/AlertContext';
import Alert from '../../components/Alert';

const PatientView = () => {
    const [activeItem] = useState(0);
    const [firstTimeLoad, setFirstTimeLoad] = useState(false);
    const patientContext = useContext(PatientContext);
    const alertContext = useContext(AlertContext);
    const { singlePatientRecord, error, clearError, successMessage, setToDefault } = patientContext;
    const { setAlert } = alertContext;

    useEffect(() => {
        if (error !== null) {
            setAlert(error, 'danger');
        }
        if (successMessage !== null) {
            if (singlePatientRecord !== null) {
                setFirstTimeLoad(true);
                setAlert(successMessage, 'success');
            } else {
                setAlert(successMessage, 'success');
                clearError();
            }
        }
    }, [singlePatientRecord, error, successMessage]);

    useEffect(() => {
        return () => {
            setToDefault();
        };
    }, []);

    return (
        <>
            <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                <CRow xs={{ gutterY: 2 }}>
                    <CCol sm={6} xs={12}>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput type="text" id="search" placeholder="Search" />
                            </div>
                        </CForm>
                    </CCol>
                    <CCol sm="auto" xs={12}>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                'Search Dropdown',
                                { label: 'One', value: '1' },
                                { label: 'Two', value: '2' },
                                { label: 'Three', value: '3' },
                            ]}
                        />
                    </CCol>
                </CRow>
                <Alert />
            </CContainer>
            <CContainer>
                <CRow xs={{ gutterY: 2 }}>
                    <CContainer>
                        {(JSON.stringify(singlePatientRecord) !== '{}') !== false &&
                        singlePatientRecord !== null &&
                        firstTimeLoad !== false ? (
                            <CRow xs={{ gutterY: 2 }}>
                                <CAccordion activeItemKey={activeItem}>
                                    <CAccordionItem>
                                        <CAccordionHeader>
                                            {singlePatientRecord !== null
                                                ? `${singlePatientRecord.name.first} ${singlePatientRecord.name.last}`
                                                : '-'}{' '}
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                                                <CRow xs={{ gutterY: 2 }}>
                                                    <CCol lg={4} md={4} sm={12} xs={12}>
                                                        <CCard>
                                                            <CCardBody>
                                                                <CCardTitle style={{ fontSize: 16 }}>
                                                                    {' '}
                                                                    {typeof singlePatientRecord.title !== 'undefined'
                                                                        ? singlePatientRecord.title
                                                                        : '-'}{' '}
                                                                </CCardTitle>
                                                                <CCardText style={{ fontSize: 13 }}> Title </CCardText>
                                                            </CCardBody>
                                                        </CCard>
                                                    </CCol>
                                                    <CCol lg={4} md={4} sm={12} xs={12}>
                                                        <CCard>
                                                            <CCardBody>
                                                                <CCardTitle style={{ fontSize: 16 }}>
                                                                    {typeof singlePatientRecord.id !== 'undefined'
                                                                        ? singlePatientRecord.id
                                                                        : '-'}
                                                                </CCardTitle>
                                                                <CCardText style={{ fontSize: 13 }}>User Id</CCardText>
                                                            </CCardBody>
                                                        </CCard>
                                                    </CCol>
                                                    <CCol lg={4} md={4} sm={12} xs={12}>
                                                        <CCard>
                                                            <CCardBody>
                                                                <CCardTitle style={{ fontSize: 16 }}>
                                                                    {typeof singlePatientRecord.email !== 'undefined'
                                                                        ? singlePatientRecord.email
                                                                        : '-'}
                                                                </CCardTitle>
                                                                <CCardText style={{ fontSize: 13 }}>
                                                                    Primary Email
                                                                </CCardText>
                                                            </CCardBody>
                                                        </CCard>
                                                    </CCol>

                                                    <CCol lg={4} md={4} sm={12} xs={12}>
                                                        <CCard>
                                                            <CCardBody>
                                                                <CCardTitle style={{ fontSize: 16 }}>
                                                                    {typeof singlePatientRecord.gender !== 'undefined'
                                                                        ? singlePatientRecord.gender
                                                                        : '-'}
                                                                </CCardTitle>
                                                                <CCardText style={{ fontSize: 13 }}>Gender</CCardText>
                                                            </CCardBody>
                                                        </CCard>
                                                    </CCol>
                                                </CRow>
                                            </CContainer>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                </CAccordion>
                            </CRow>
                        ) : (
                            <CCol lg={12} md={12} sm={12} xs={12}>
                                {firstTimeLoad === false ? (
                                    <CCard>
                                        <CCardBody>
                                            <div className="text-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                ) : (
                                    <CCard>
                                        <CCardBody>
                                            <div className="text-center">
                                                <h3>No Data Found!</h3>
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                )}
                            </CCol>
                        )}
                    </CContainer>
                </CRow>
            </CContainer>

            <CContainer>
                <CRow xs={{ gutterY: 2 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: 50,
                        }}
                    >
                        <CPagination size="sm" aria-label="Pagination">
                            <CPaginationItem>Previous</CPaginationItem>
                            <CPaginationItem>1</CPaginationItem>
                            <CPaginationItem>2</CPaginationItem>
                            <CPaginationItem>3</CPaginationItem>
                            <CPaginationItem>4</CPaginationItem>
                            <CPaginationItem>5</CPaginationItem>
                            <CPaginationItem>6</CPaginationItem>
                            <CPaginationItem>Next</CPaginationItem>
                        </CPagination>
                    </div>
                </CRow>
            </CContainer>
        </>
    );
};

export default PatientView;
