import { useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';

import { FormDropDownList } from '../../components/forms/FormComponents';
import { useLocationStore, usePrismicStore, useProfessionalsStore } from '../../zustandStore';

const SelectTemplate = ({ edited, formData, setFormData }) => {
    const { t, i18n } = useTranslation(['common']);

    const [professionalsArray, setProfessionalsArray] = useState([]);
    const [templateArray, setTemplateArray] = useState([]);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);
    const professionals = usePrismicStore((state) => state.professionals);
    const consentFormDocuments = usePrismicStore((state) => state.consentFormDocuments);
    const cachedUserData = useProfessionalsStore((state) => state.cachedUserData);

    const generateRecord = () => {
        const filteredProfessionalsKeys = new Set(
            cachedUserData
                .filter(
                    (item) =>
                        item.prismic_customer_config.data.customer_id[0].text === currentCustomer.customer_id[0].text &&
                        item.dc_user.instance_id === selectedLocation.instanceId
                )
                .map((item) => item.prismic_profile.data.key[0].text)
        );
        const array = Object.values(professionals)
            .filter((entry) => filteredProfessionalsKeys.has(entry.key[0]?.text))
            .map((a) => {
                return {
                    value: a.key[0].text,
                    label: a.display_name[0].text,
                    id: a.key[0].text,
                    key: a.key[0].text,
                };
            });
        const templates = consentFormDocuments
            .filter((entry) => entry.data.key)
            .map((a) => {
                return {
                    data: a.data,
                    lan: a.data,
                    key: a.data.key[0].text,
                    label: a.data.template_name[0].text,
                    value: a.data.key[0].text,
                };
            });
        const select = 'Bitte auswählen';
        array.splice(0, 0, select);
        templates.splice(0, 0, select);
        setProfessionalsArray(array);
        setTemplateArray(templates);
    };

    useEffect(() => {
        if (consentFormDocuments && professionals && cachedUserData && selectedLocation && currentCustomer) {
            generateRecord();
        }
    }, [consentFormDocuments, professionals, i18n.resolvedLanguage, selectedLocation, currentCustomer, cachedUserData]);

    const onChange = (e, data) => {
        if (e.target.name === 'professional') {
            setFormData({
                ...formData,
                professional: {
                    key: data[0].key,
                    id: data[0].id,
                    label: data[0].label,
                },
            });
        } else if (e.target.name === 'template_key') {
            // eslint-disable-next-line no-param-reassign
            formData.templates_image = [];
            setFormData({
                ...formData,
                template_key: data[0].key,
                template_name: data[0].label,
            });
            if (data[0]?.key === 'treatment-contract-extended') {
                formData.templates_image?.push(data[0]?.data?.preview_image_personal_info?.url);
                formData.templates_image?.push(data[0]?.data?.preview_image_2_optional?.url);
            } else {
                formData.templates_image?.push(data[0]?.data?.preview_image?.url);
                formData.templates_image?.push(data[0]?.data?.preview_image_2?.url);
            }
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const addTemplateImages = () => {
        if (templateArray?.length > 0) {
            for (let i = 0; i < templateArray.length; i++) {
                if (templateArray[i]?.key === formData.template_key) {
                    if (formData.template_key === 'treatment-contract-extended') {
                        formData?.templates_image?.push(templateArray[i]?.data?.preview_image_personal_info?.url);
                        formData?.templates_image?.push(templateArray[i]?.data?.preview_image_2_optional?.url);
                    } else {
                        formData?.templates_image?.push(templateArray[i]?.data?.preview_image?.url);
                        formData?.templates_image?.push(templateArray[i]?.data?.preview_image_2?.url);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (edited) {
            addTemplateImages();
        }
    }, [templateArray, professionalsArray]);

    return (
        <>
            <CCol className="pt-5 pb-3 custom-form-text" xs={12} sm={12} lg={12}>
                {t('Choose a Behadler : inside and a Template:')}
            </CCol>
            <CRow>
                <CCol xs={6} sm={12} lg={6} className="pb-2">
                    <FormDropDownList
                        name="professional"
                        value={formData?.professional?.key}
                        label={`${t('Practitioners')}`}
                        hint="Hint: Only European countries"
                        onChange={(e) =>
                            onChange(
                                e,
                                professionalsArray.filter((professional) => professional.value === e.target.value)
                            )
                        }
                        data={professionalsArray}
                        formData={formData}
                        setFormData={setFormData}
                        disabled={edited}
                    />
                </CCol>
                <CCol xs={6} sm={12} lg={6}>
                    <FormDropDownList
                        name="template_key"
                        value={formData?.template_key}
                        label={`${t('Presentation')}`}
                        hint="Hint: Only European countries"
                        onChange={(e) =>
                            onChange(
                                e,
                                templateArray.filter((professional) => professional.value === e.target.value)
                            )
                        }
                        data={templateArray}
                        formData={formData}
                        setFormData={setFormData}
                        disabled={edited}
                    />
                </CCol>
            </CRow>
        </>
    );
};

export default SelectTemplate;
