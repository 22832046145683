const Quicksight = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <iframe
                style={{ width: '100%', height: '78vh' }}
                title="Quicksight"
                src="https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/992382421089/dashboards/55459129-fc78-43e9-8fc4-19c042f56b02?directory_alias=eterno-analytics-dev"
            />
        </div>
    );
};

export default Quicksight;
