import { useEffect } from 'react';

import useAuth from './useAuth';
import { useLocationStore, usePrismicStore } from '../zustandStore';

const useLocations = () => {
    const { user } = useAuth();
    const instanceConfigs = usePrismicStore((state) => state.instanceConfigs);
    const locationHubs = usePrismicStore((state) => state.locationHubs);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);

    useEffect(() => {
        if (!user || !locationHubs || !currentCustomer || !instanceConfigs) return;

        if (!user.groups) {
            useLocationStore.setState({ hasFailed: true });
            return;
        }

        const currentCustomerLocationIds = currentCustomer.locations.map(({ location }) => location.id);
        const locations = locationHubs
            .filter(
                (hub) =>
                    (user.groups?.includes('admin') || user.groups?.includes(hub.data.group_key[0].text)) &&
                    currentCustomerLocationIds.includes(hub.id) &&
                    instanceConfigs?.[hub.id]?.instance_id?.[0]?.text
            )
            .map((hub) => ({
                name: hub.data.title[0].text,
                key: hub.data.key[0].text,
                instanceId: instanceConfigs[hub.id]?.instance_id[0].text,
                config: instanceConfigs[hub.id],
            }));

        useLocationStore.setState({ locations: locations, hasFailed: false });

        const selectedLocation =
            locations.find((location) => location.key === localStorage.getItem('selectedLocation')) ?? locations[0];

        useLocationStore.setState({ selectedLocation: selectedLocation ?? {} });
    }, [user, locationHubs, currentCustomer, instanceConfigs]);
};

export default useLocations;
