import { CFormSelect } from '@coreui/react';

import { useLocationStore, usePrismicStore, useTodaysAppointmentsStore } from '../zustandStore';

const ProfessionalSelection = ({ selectedProfessional, setSelectedProfessional, disabled = false }) => {
    const professionals = usePrismicStore((state) => state.professionals);
    const professionalMappings = useTodaysAppointmentsStore((state) => state.professionalMappings);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);

    return (
        <CFormSelect
            disabled={disabled}
            options={[
                { label: 'Professional auswählen', value: '-' },
                ...Object.keys(professionals)
                    .filter(
                        (key) =>
                            professionalMappings[key]?.dc_instance_id === selectedLocation.instanceId &&
                            professionals[key].location_hub.id === selectedLocation.config.hub.id
                    )
                    .sort((a, b) =>
                        professionals[a].display_name[0].text.localeCompare(professionals[b].display_name[0].text)
                    )
                    .map((professional) => {
                        return {
                            label: professionals[professional].display_name[0].text,
                            value: professionals[professional].key[0].text,
                        };
                    }),
            ]}
            value={selectedProfessional}
            onChange={(e) => setSelectedProfessional(e.target.value)}
            name="professionalSelect"
        />
    );
};

export default ProfessionalSelection;
