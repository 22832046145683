import { CButton } from '@coreui/react';

const QuestionOption = ({ question, selectedQuestions, setSelectedQuestions, problem = null }) => {
    const openPrismicLink = (prismicId) => {
        window.open(
            `https://eh-patients.prismic.io/documents~k=custom_question&w=&b=working&c=published&l=de-de/${prismicId}/?section=Main`,
            'SingleSecondaryWindow',
            'noreferrer'
        );
    };

    const onClick = () => {
        if (selectedQuestions[question.question_key[0]?.text]) {
            const newSelectedQuestions = { ...selectedQuestions };
            delete newSelectedQuestions[question.question_key[0]?.text];
            setSelectedQuestions(newSelectedQuestions);
        } else {
            setSelectedQuestions({
                ...selectedQuestions,
                [question.question_key[0]?.text]: true,
            });
        }
    };

    return (
        <div
            style={{
                borderRadius: '8px',
                border:
                    question.question_key[0]?.text && selectedQuestions[question.question_key[0]?.text]
                        ? '2px solid #FFC877'
                        : '2px solid #D5D5D5',
                padding: '1rem',
                cursor: !problem ? 'pointer' : 'auto',
                fontSize: '0.9rem',
            }}
            onClick={onClick}
        >
            <div className="d-flex align-items-center justify-content-between">
                <h6
                    style={{
                        fontSize: '0.9rem',
                        maxWidth: problem ? '70%' : '100%',
                    }}
                >
                    {question.title[0]?.text || '-'}
                </h6>
                {problem && (
                    <CButton color="secondary" size="sm" onClick={() => openPrismicLink(question.prismic_id)}>
                        Prismic Link
                    </CButton>
                )}
            </div>
            <p style={{ color: '#ABABAB' }} className="p-0 m-0">
                {!problem ? `Typ: ${question.question_type}` : `Problem: ${problem}`}
            </p>
        </div>
    );
};

export default QuestionOption;
