import { useTranslation } from 'react-i18next';

const AccessDeniedFallback = () => {
    const { t } = useTranslation();

    return (
        <div
            style={{ height: '70vh' }}
            className="d-flex flex-column align-items-center justify-content-center gap-4 text-center"
        >
            <h4 className="w-50" style={{ lineHeight: '2.5rem' }}>
                {t('AccessDeniedTitle')}
            </h4>
            <p className="w-50">
                {t('AccessDeniedInfo')} <a href="mailto:tech.admin@eterno.health">tech.admin@eterno.health</a>
            </p>
        </div>
    );
};

export default AccessDeniedFallback;
