import { useAdminConfigStore, usePrismicStore } from '../zustandStore';

const CustomerDropdown = ({ myCustomers }) => {
    const customers = useAdminConfigStore((state) => state.customers);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);

    const handleCustomerChange = (event) => {
        usePrismicStore.setState({
            currentCustomer: customers[event.target.value],
        });
    };

    if (myCustomers.length < 2) {
        return null;
    }

    return (
        <select
            disabled={!customers}
            className="mx-3 py-1 my-1"
            onChange={handleCustomerChange}
            value={currentCustomer?.key[0].text}
        >
            {myCustomers.map((customer) => (
                <option key={customer.key[0].text} value={customer.key[0].text}>
                    {customer.customer_name[0].text}
                </option>
            ))}
        </select>
    );
};

export default CustomerDropdown;
