import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CCard, CCardBody, CCardText, CCardTitle, CCol, CFormSelect } from '@coreui/react';

import PatientContext from '@context/patient/PatientContext';

const PatientAccountInfoModal = ({ func }) => {
    const { t } = useTranslation(['common']);
    const patientContext = useContext(PatientContext);
    const { patientAccountDetails, successMessage, error } = patientContext;

    const [patientAccountInfo, setPatientAccountInfo] = useState({
        privacy_policy_status: '',
        status: '',
        terms_and_conditions_status: '',
        use_of_analytics_status: '',
        user_id: '',
        verification_status: '',
        locale: {
            language: '',
            country: '',
        },
    });

    func(patientAccountInfo);

    const patientAccountOnChange = (e) => {
        setPatientAccountInfo({
            ...patientAccountInfo,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (patientAccountDetails !== null) {
            setPatientAccountInfo(patientAccountDetails);
        }
    }, [patientAccountDetails, successMessage, error]);

    return patientAccountInfo !== null && (JSON.stringify(patientAccountInfo) === '{}') !== true ? (
        <>
            <CCol lg={12} md={12} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Account Information')} </CCardTitle>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {patientAccountInfo.user_id} </CCardTitle>
                        <CCardText style={{ fontSize: 13 }}> User Id </CCardText>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Privacy Policy Status')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Unknown')}`, value: `${t('Unknown')}` },
                                { label: `${t('Accepted')}`, value: `${t('Accepted')}` },
                                { label: `${t('Declined')}`, value: `${t('Declined')}` },
                            ]}
                            name="privacy_policy_status"
                            value={patientAccountInfo.privacy_policy_status}
                            onChange={(e) => patientAccountOnChange(e)}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Status')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Active')}`, value: `${t('Active')}` },
                                { label: `${t('Inactive')}`, value: `${t('Inactive')}` },
                            ]}
                            name="status"
                            value={patientAccountInfo.status}
                            onChange={patientAccountOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Terms And Conditions Status')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Unknown')}`, value: `${t('Unknown')}` },
                                { label: `${t('Accepted')}`, value: `${t('Accepted')}` },
                                { label: `${t('Declined')}`, value: `${t('Declined')}` },
                            ]}
                            name="terms_and_conditions_status"
                            value={patientAccountInfo.terms_and_conditions_status}
                            onChange={patientAccountOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Use Of Analytics Status')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Unknown')}`, value: `${t('Unknown')}` },
                                { label: `${t('Accepted')}`, value: `${t('Accepted')}` },
                                { label: `${t('Declined')}`, value: `${t('Declined')}` },
                            ]}
                            name="use_of_analytics_status"
                            value={patientAccountInfo.use_of_analytics_status}
                            onChange={patientAccountOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Verification Status')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Unknown')}`, value: `${t('Unknown')}` },
                                { label: `${t('Verified')}`, value: `${t('Verified')}` },
                                { label: `${t('Unverified')}`, value: `${t('Unverified')}` },
                            ]}
                            name="verification_status"
                            value={patientAccountInfo.verification_status}
                            onChange={patientAccountOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        </>
    ) : (
        ''
    );
};

export default PatientAccountInfoModal;
